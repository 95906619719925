import React, { useState } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { IonToast, IonContent, IonButton } from '@ionic/react';

const ToastContainer: React.FC = () => {

  const setIsOpen = useStoreActions((s: any) => s.toast.setIsOpen);
  const isOpen = useStoreState((s: any) => s.toast.isOpen);
  const text = useStoreState((s: any) => s.toast.text);

  return (
    <IonContent>
      <IonToast
        isOpen={isOpen}
        onDidDismiss={() => setIsOpen(false)}
        message={text}
        duration={1000}
      />
    </IonContent>
  );
};

export default ToastContainer;