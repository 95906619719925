import React, { useState } from 'react';
import { useStoreState } from 'easy-peasy';
import { IonAvatar, IonItem, IonLabel, IonList } from '@ionic/react';
import Marquee from 'react-text-marquee';
import Webcam from "react-webcam";
import './Team.scss';

import Me from '../../WebrtcClient/Me';
import Peer from '../../WebrtcClient/Peer';


interface TeamProps {
    state: any;
    isHome?: boolean;
    activeTeamColour?: string;
    activeTeamColourAccent?: string
}

const Team: React.FC<TeamProps> = ({state={}, isHome = true, activeTeamColour = "#36454f", activeTeamColourAccent = "#929292"}) => {

    const user = useStoreState((store: any) => store.auth.user);
    
    const teamId = state.teamId;
    const [teamMembers, setTeamMembers] = useState<Array<any> | null>();
    const team = useStoreState((store: any) => store.room.state?.commonState?.teams?.[teamId]);
    const players = useStoreState((store: any) => store.room.state?.commonState?.players);
    const currentPlayerId = useStoreState((store: any) => store.room.state?.commonState?.currentPlayerId);

    let teamPlayers: string[];
    if (team && team.playerIds) {
        teamPlayers = team.playerIds.map((uid: string) => { return players[uid]; });
    } else {
        teamPlayers = [];
    }

    const webrtcClient = useStoreState((s: any) => s.webrtcClient.webrtcClient);

    React.useEffect(() => {
        webrtcClient._startProducer();
    }, []);


    React.useEffect(() => {
        if (webrtcClient) {
            teamPlayers.map((player: any) => {
                webrtcClient.consume(player.uid);
            });
        }
    }, [teamPlayers]);

    /*
    const fetchDummyData = () => {
        fetch("https://randomuser.me/api/?results=3")
            .then(response => response.json())
            .then(data => {
                const array = data.results.map((result: any) => ({
                    ...result,
                    displayName: `${result.name.first} ${result.name.last}`,
                    avatar: result.picture.medium,
                    location: result.location.city,
                }))
                setTeamMembers(array)
            })
    }

    React.useEffect(() => {
        fetchDummyData();
    }, [])
    */
    
    return (
        <div className={`lbb-team ${isHome && "home"}`}>
            <IonList className="member-list" lines="none">
                {
                    teamPlayers.map((player: any, index: number) => {
                        return (
                            <IonItem className={player.won ? 'team-member add-shadow is-won' : 'team-member add-shadow'} key={index}>
                                <IonAvatar>
                                    {player.uid == user.uid ? <Me width={'100%'} height={'100%'} /> : <Peer width={'100%'} height={'100%'} id={player.uid} />}
                                    <IonLabel className="avatar-label">
                                        <Marquee text={player.displayName + (player.score ? '('+player.score+')' : '')} />
                                    </IonLabel>
                                </IonAvatar>
                            </IonItem>
                        )
                    })
                }
            </IonList>
            <div className="team-name-wrapper">
                <h1 className="add-shadow">Team {teamId}</h1>
            </div>
        </div>
    )
}

export default Team