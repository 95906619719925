import React, { useState, useEffect } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';

import FriendsClient from './FriendsClient'

export const RoomContext = React.createContext(undefined);

const FriendsClientProvider = (props) => {

	const storeActions = useStoreActions(x => x.friends);
	const friendsClient = useStoreState(x => x.friends.friendsClient);
	const isJoined = useStoreState(x => x.room.isJoined);

	useEffect(() => {
		if (!props.user) {
			return;
		}
		if (!isJoined) {
			return;
		}

		// eslint-disable-next-line
		const initFriendsClient = (roomId, user) => {
		    return new FriendsClient(
		      {
		      	storeActions,
		        roomId,
		        peerId: user.uid,
		        peerDisplayName: user.displayName
		      });
		}

        if (friendsClient) {
            friendsClient.close();
        }
		const fc = initFriendsClient(props.roomId, props.user);
		storeActions.setFriendsClient(fc);
	}, [storeActions, props.user, isJoined]);

    return (
	    <RoomContext.Provider value={friendsClient}>
        	{props.children}	    
        </RoomContext.Provider>
    )

};

export default FriendsClientProvider
