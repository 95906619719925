import React, {useState} from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { IonAvatar, IonButton, IonCol, IonDatetime, IonGrid, IonInput, IonItem, IonLabel, IonPickerColumn, IonRow, IonText, IonTextarea } from '@ionic/react';


const Profile: React.FC = () => {
    const user = useStoreState((s: any) => s.auth.user);
    const [displayName, setDisplayName] = useState(user.displayName);
    const [uid, setUid] = useState(user.uid);
    const [email, setEmail] = useState(user.email);
    const [dateOfBirth, setDateOfBirth] = useState('');
    const [location, setLocation] = useState('');
    const [bio, setBio] = useState('');
 
    const [file, setFile] = useState('');

    return (
        <IonGrid>
            <IonRow className="board-title">
                <IonCol>
                  <IonText>
                    <h2>MY PROFILE</h2>
                  </IonText>  
                </IonCol>
            </IonRow>
            <IonRow className="dashboard-highlight">
                <IonCol className="">
                    <div className="profile-avatar">
                        <img src={`https://storage.googleapis.com/laboum-live.appspot.com/avatars/${uid}.jpg`} alt={`${displayName} avatar`} />
                    </div>
                
                    <div className="display-name-wrapper">
                     
                        {user.displayName}
                    </div>
                    <IonButton className="dashboard-button">
                        CHANGE PROFILE PICTURE
                       
                    </IonButton>
                </IonCol>
            </IonRow>

            <IonRow className="ion-margin-top">
            <IonCol size="8">
              <IonItem>
                <IonLabel position="stacked">Display name</IonLabel>
                <IonInput type="text" value={displayName} onInput={(e: any) => setDisplayName(e.target.value)} />
              </IonItem>
              <IonItem>
                <IonLabel position="stacked">Email address <small>(Visible only to friends)</small></IonLabel>
                <IonInput type="email" value={email} onInput={(e: any) => setEmail(e.target.value)} />
              </IonItem>
              <IonItem>
                <IonLabel position="stacked">Date of birth</IonLabel>
                <IonDatetime value={dateOfBirth} onIonChange={(e: any) => setDateOfBirth(e.target.value)} />
              </IonItem>
              <IonItem>
                <IonLabel position="stacked">Location</IonLabel>
                <IonInput type="text" value={location} placeholder="Add your location" onInput={(e: any) => setLocation(e.target.value)} />
              </IonItem>
              <IonItem>
                <IonLabel position="stacked">Bio</IonLabel>
                <IonTextarea value={bio} onIonChange={e => setBio(e.detail.value!)}></IonTextarea>
              </IonItem>
              
            </IonCol>
            </IonRow>
        </IonGrid>
    )
}

export default Profile;