import React from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';

import './DragAndDropBuilder.css';

import _uniqueId from 'lodash/uniqueId';
import useWindowDimensions from './utilities/useWindowDimensions';

import {
    IonButton, 
    IonSelect, 
    IonSelectOption, 
    useIonViewWillEnter,
  } from '@ionic/react';

  import { addCircle } from 'ionicons/icons';


import { catalogue as laBoumLibrary, moduleFactory } from './laBoumLibrary'

import { Responsive, WidthProvider, Layout as GridLayout, Layout } from 'react-grid-layout';

import { useGlobalStyles } from './customHooks/useGlobalStyles';

const ResponsiveReactGridLayout = WidthProvider(Responsive);

function uniqueId(prefix: string, counter: number ) {
  let id = ++counter;
  return `${prefix}-${counter}`;
}

interface ContainerProps { }

const DragAndDropBuilder: React.FC<ContainerProps> = () => {
    const [showGrid, setShowGrid] = React.useState<boolean>(false)
    const { height, width } = useWindowDimensions();
    const [selectedModule, setSelectedModule] = React.useState<string>();

    const setLayout = useStoreActions((store: any) => store.room.setLayout);
    const putLayout = useStoreActions((store: any) => store.room.putLayout);
    const layout = useStoreState((store: any) => store.room.layout);
    const roomId = useStoreState((store: any) => store.room.roomId);
    
    const globalStyles = useGlobalStyles(false, "#36454f", "#929292");

    useIonViewWillEnter(() => {
        setShowGrid(true);
    });


    const onLayoutChange = (lo: Layout[]) => {
      const newLayout = layout.lg.map((element: any, index: number) => {
          const le = lo.find(e => e.i === element.i );
          if (le) {
            return {
              ...element, x: le.x, y: le.y, w: le.w, h: le.h, static: le.static
            }          
          } else {
            return element; 
          }
      });
      setLayout({ ...layout, lg: newLayout });
    }

    const addItemToGrid = (event: any) => {
      if (event.detail.value !== undefined) {
        const selectedModule = laBoumLibrary.find(object => {
          return object.name === event.detail.value
        })

        const newGridItem = {
          i: uniqueId("lbb", layout.lg.length), 
          x: 0,
          y: 1,
          w: 3,
          h: 2,
          static: false,
          moduleName: selectedModule!.name,
          state: {
            title: 'Hello video',
            url: ''
          },
        }
        console.log("Builder layout", layout)
        setLayout({...layout, lg: [...layout.lg, newGridItem]})
    }
  }

  const saveGrid = () => {
    putLayout({ id: roomId, layout });
    localStorage.setItem('gridLayout', JSON.stringify(layout))
  }

  return (
    <div className="grid-container">
        {
          showGrid &&
          <ResponsiveReactGridLayout
              className="layout"
              breakpoints={{lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0}}
              cols={{lg: 12, md: 10, sm: 6, xs: 4, xxs: 2}}
              measureBeforeMount={true}
              onLayoutChange={onLayoutChange}
              useCSSTransforms={false}
              layouts={layout}
              draggableCancel=".editor-view-container"
              // isResizable={false}
              rowHeight={(height > 700) ? height/10 : 75}
          >
            {
              layout.lg.map((item: any, index: number) => {
                console.log("asdasda", item);
                return (<div className="lbb-module-wrapper" key={item.i}>{moduleFactory(item)}</div>);
              })
            }
          </ResponsiveReactGridLayout>
        }
        <div className="add-module">
      <IonSelect value={selectedModule} okText="Add to grid" placeholder="Add module" onIonChange={addItemToGrid}>
          {
            laBoumLibrary.map((module, index)=>{
              return (
                <IonSelectOption value={module.name} key={module.name}>
                  {module.name}
                </IonSelectOption>
              )
            }) 
          }
        </IonSelect>
        <IonButton color="light" onClick={saveGrid}>Save</IonButton>
        </div>
    </div>
  );
};

export default DragAndDropBuilder;