import firebase from 'firebase';


export const saveMessage = (roomId: string, { userId, userDisplayName, text }: any) => {
  // Add a new message entry to the database.
  return firebase.firestore().collection('chat').doc(roomId).collection('messages').add({
    userId, userDisplayName, text,
    timestamp: firebase.firestore.FieldValue.serverTimestamp()
  }).catch(function(error) {
    console.error('Error writing new message to database', error);
  });
};

export const loadMessages = (roomId: string, newMessage: any) => {
  var query = firebase.firestore()
                  .collection('chat')
                  .doc(roomId)
                  .collection('messages')
                  .orderBy('timestamp', 'asc')
                  .limitToLast(10);

  // Start listening to the query.
  query.onSnapshot(function(snapshot) {
  	if (snapshot.metadata.hasPendingWrites) {
  		return false;
  	}
    snapshot.docChanges().forEach(function(change) {
      if (change.type === 'removed') {
        //deleteMessage(change.doc.id);
      } else {
        var message = change.doc.data();
        newMessage(message);
      }
    });
  });
};