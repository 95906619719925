import { action, thunk } from 'easy-peasy';
import axios from 'axios';
import { getProfileApiUrl } from '../urlFactory'

const joinModalModel = {

    joinData: {},

    isOpen: true,
    loading: true,
    isDisplayNameUpdated: false,

    setIsOpen: action((state, isOpen) => {
      state.isOpen = isOpen;
    }),

    setLoading: action((state, loading) => {
      state.loading = loading;
    }),

    setJoinData: action((state, joinData) => {
      state.joinData = joinData;
    }),

    saveJoinData: thunk(async (actions, joinData, { getStoreActions } ) => {
      if (joinData.displayName) {
        try {
          axios.post(getProfileApiUrl('/save-display-name'), { displayName: joinData.displayName })
            .then(response => {
              getStoreActions().auth.setDisplayName(joinData.displayName);
              getStoreActions().toast.makeToast("Display name changed to: " + joinData.displayName);
              getStoreActions().joinModal.setJoinData(joinData);
            })
            .catch(err => {
              console.log(err);
            });
        } catch (e) {
          console.log(e);//TODO
        }
      } else {
        getStoreActions().joinModal.setJoinData(joinData);
      }
    }),

};

export default joinModalModel;