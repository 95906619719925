import React, { useEffect, useState, useRef } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';

import Countdown from './Countdown';
import ProgressBar from './ProgressBar';
import Kick from './Kick';


import Me from '../../WebrtcClient/Me';
import Peer from '../../WebrtcClient/Peer';

import './WebrtcQueue.css';
import { IonBadge } from '@ionic/react';

import PlaceholderGifs from './PlaceholderGifs';


const WebrtcQueue = (props) => {

	const user = useStoreState(s => s.auth.user);
	const webrtcClient = useStoreState(s => s.webrtcClient.webrtcClient);
	const isConnected = useStoreState(s => s.webrtcClient.isConnected);

	const state = useStoreState(s => s.room.state);
	const moderators = useStoreState(s => s.room.moderators);
	const entries = state.entries;

	const updateLocalState = useStoreActions(s => s.room.updateLocalState);

	const localEntries = useRef(new Map());
	const webcamNeededUntilRef = useRef(null);
	const [isCountdownVisible, setIsCountdownVisible] = useState(null);

	//const onAir = (state && state.queueHead) ? state.queueHead.uid : null;  
	const [onAir, setOnAir] = useState(null);
	//const isModerator = moderators.find(u => user.uid == u.uid);
	const isModerator = user.isAdmin;



	const cooldownSeconds = 3;
	const onAirSeconds = 15;
	const countdownSeconds = 10;
	const warmupSeconds = 4;
	const adminOffset = user.isAdmin ? 4000 : 0;

	const addEntry = (entry) => {

		localEntries.current.set(entry.entryId, entry);

		// If its me, prepare producer
		if (entry.uid == user.uid) {

			// Calculate until when the cam should be on.
			const now = new Date();
			const ts = new Date(now.getTime() + entry.showOffset + (onAirSeconds + 1) * 1000);
			if (webcamNeededUntilRef.current < ts) {
				webcamNeededUntilRef.current = ts;
			}

			// Start the producer: immediately.
			setTimeout(() => {
				webrtcClient._startProducer();
			}, 0);


			// Show the coundown on T-10
			if (entry.showOffset > (countdownSeconds * 1000)) {
				setTimeout(() => {
					setIsCountdownVisible(true);
				}, (entry.showOffset) - (countdownSeconds * 1000));
			}

			// Hide countdown on T
			setTimeout(() => {
				setIsCountdownVisible(false);
				setOnAir(entry);
			}, entry.showOffset);

			// Disable webcam after onair.
			setTimeout(() => {
				if (webcamNeededUntilRef.current < new Date()) {
					webrtcClient.disableWebcam();
				}
			}, (entry.showOffset + (onAirSeconds + cooldownSeconds) * 1000));
		} else {
			// If its not me, then prepare consuming in time.

			setTimeout(() => {
				if (webrtcClient && isConnected && onAir) {
					webrtcClient.consume(entry.uid);
				}
			}, entry.showOffset - (warmupSeconds * 1000));

			setTimeout(() => {
				setOnAir(entry);
			}, entry.showOffset - adminOffset);

		}
	};

	useEffect(() => {
		updateLocalState({ onAir: onAir });
	}, [onAir]);

	useEffect(() => {
		if ((webrtcClient && isConnected && state && entries)) {
			entries.forEach(e => {
				if (!localEntries.current.has(e.entryId)) {
					const showOffset = e.showDate - state.now;
					if (showOffset > -10000) {
						addEntry({ ...e, showOffset });
					}
				}
			});
		}
	}, [entries]);

	const renderSpotlightVideo = () => {

		if (onAir && webrtcClient) {
			const isMe = onAir.uid == user.uid;
			return (
				<div style={{ height: '100%' }} key={'queue-spotlight'}>
					{isMe ? <Me /> : <Peer id={onAir.uid} />}
					{
						isModerator ? (<Kick uid={onAir.uid}></Kick>) : null
					}
					<Countdown isVisible={isCountdownVisible} />
					<ProgressBar onAir={isMe} onAirSeconds={onAirSeconds} />
				</div>
			);
		} else {
			//return null;
			return (
				<PlaceholderGifs />
			)
		}
	}
	return (
		<div className="lbb-webrtc-queue-container">

			{renderSpotlightVideo()}
			{
				(onAir && webrtcClient) ? <IonBadge className="live-badge" color="light">LIVE</IonBadge> : null
			}
		</div>
	);
};

export default WebrtcQueue;
