import { action, thunk, computed } from 'easy-peasy';

import { getLayoutById, putLayout } from '../services/roomService';

const roomModel = {
    
    isJoined: false,
    roomId: null,
    appId: null,
    roomClient: null,
    isLoading: false,
    players: [],
    moderators: [],
    state: {},
    waitingRoom: {
      rank: null
    },
    localState: {
      onAir: null
    },
    isAdmin: false,
	layout: {
		lg: [
			/*{
		      i: "a", x: 0, y: 0, w: 6, h: 3, moduleName: 'Video',
		      state: {
		        title: 'Hello video',
		        url: 'https://youtube.com'
		      }
		    }*/
		],
    md: []
	},

	setElementState: action((state, payload) => {
		state.layout.lg = state.layout.lg.map(el => {
			if (el.i === payload.i) {
				return {...el, state: payload.state};
			} else {
        return {...el};
      }
		});
	}),

	getLayoutById: thunk(async (actions, id) => {
      actions.setIsLoading(true);
      try {
        getLayoutById(id).then(doc => {
        	const data = doc.data();
        	if (data && data.layout) {
	        	actions.setLayout(data.layout);
			    actions.setIsLoading(false);
        	}
        })
        .catch(err => {});
      } catch (e) {
        // actions.setError(e); TODO
      }
    }),

	putLayout: thunk(async (actions, payload) => {
      actions.setIsLoading(true);
      try {
        const { data } = await putLayout(payload);
      } catch (e) {
        console.log(e);
        // actions.setError(e); TODO
      }
      actions.setIsLoading(false);
    }),

    emit: action((state, payload) => {
      if (state.roomClient) {
	    state.roomClient.emit(payload);
      }
    }),

    setRoomClient: action((state, roomClient) => {
      state.roomClient = roomClient;
    }),


    setIsLoading: action((state, isLoading) => {
      state.isLoading = isLoading;
    }),

    setIsAdmin: action((state, isAdmin) => {
      state.isAdmin = isAdmin;
    }),

    setRoomParams: action((state, {roomId, appId}) => {
      state.roomId = roomId;
      state.appId = appId;
    }),

    setLayout: action((state, layout) => {
      state.layout = layout;
    }),

    setConnectionState: action((state, connectionState) => {
      state.connectionState = connectionState;
    }),

    setWaitingRoomRank: action((state, rank) => {
      state.waitingRoom.rank = rank;
    }),

    setIsJoined: action((state, isJoined) => {
      state.isJoined = isJoined;
    }),

    setState: action((state, rs) => {
      state.state = rs;
     }),

    updateLocalState: action((state, ls) => {
      state.localState = { ...state.localState, ...ls };
     }),

    notify: action((state, payload) => {
  	  console.log(JSON.stringify(payload));
    })



};

export default roomModel;