import React from 'react';

import Chat from './Chat';
import Friends from './Friends';
import Queue from './Queue';
import Rankings from './Rankings';
import Video from './Video';
import Rating from './Rating';
import YouTubeVideo from './YouTubeVideo';
import PartyPeople from './PartyPeople';
import HlsVideo from './HlsVideo';
import WebrtcQueue from './WebrtcQueue';
import FriendButton from './FriendButton';
import EventInfo from './EventInfo';
import CurrentDancerInfo from './CurrentDancerInfo';
import RocketTabs from './RocketTabs';
import Team from './Team';
import Actor from './Actor';
import ScoreBoard from './ScoreBoard';

const moduleFactory = (item: any) => {

  if (item.moduleName === 'Chat') {
    return (<Chat {...item} />);
  } else if (item.moduleName === 'Rankings') {
    return (<Rankings {...item} />);
  } else if (item.moduleName === 'Friends') {
    return (<Friends {...item} />);
  } else if (item.moduleName === 'Queue') {
    return (<Queue {...item} />);
  } else if (item.moduleName === 'Video') {
    return (<Video  {...item} />);
  } else if (item.moduleName === 'Rating') {
    return (<Rating {...item} />);
  } else if (item.moduleName === 'YouTubeVideo') {
    return (<YouTubeVideo {...item} />);
  } else if (item.moduleName === 'PartyPeople') {
    return (<PartyPeople {...item} />);
  } else if (item.moduleName === 'HlsVideo') {
    return (<HlsVideo {...item} />);
  } else if (item.moduleName === 'WebrtcQueue') {
    return (<WebrtcQueue {...item} />);
  } else if (item.moduleName === 'FriendButton') {
    return (<FriendButton {...item} />);
  } else if (item.moduleName === 'EventInfo') {
    return (<EventInfo {...item} />);
  } else if (item.moduleName === 'CurrentDancerInfo') {
    return (<CurrentDancerInfo {...item} />);
  } else if (item.moduleName === 'RocketTabs') {
    return (<RocketTabs {...item} />);
  } else if (item.moduleName === 'Team') {
    return (<Team {...item} />);
  } else if (item.moduleName === 'Actor') {
    return (<Actor {...item} />);
  } else if (item.moduleName === 'ScoreBoard') {
    return (<ScoreBoard {...item} />);
  } else {
    return (<div>Undefined module</div>);
  }

};

const catalogue = [
  {
    name: "Chat",
    description: "",
  },
  {
    name: "Friends",
    description: "",
  },
  {
    name: "Queue",
    description: "",
  },
  {
    name: "Rankings",
    description: "",
  },
  {
    name: "Video",
    description: "",
  },
  {
    name: "Rating",
    description: "",
  },
  {
    name: "YouTubeVideo",
    description: "",
  },
  {
    name: "PartyPeople",
    description: "",
  },
  {
    name: "HlsVideo",
    description: "",
  },
  {
    name: "WebrtcQueue",
    description: "",
  },
  {
    name: "FriendButton",
    description: "",
  },
  {
    name: "EventInfo",
    description: "",
  },
  {
    name: "CurrentDancerInfo",
    description: "",
  },
  {
    name: "RocketTabs",
    description: "",
  },
  {
    name: "Team",
    description: "",
  },
  {
    name: "Actor",
    description: "",
  },
  {
    name: "ScoreBoard",
    description: "",
  }

]

export { 
  Chat, 
  Friends, 
  Queue, 
  Rankings, 
  Rating, 
  Video, 
  YouTubeVideo, 
  HlsVideo, 
  PartyPeople, 
  WebrtcQueue, 
  FriendButton, 
  EventInfo, 
  CurrentDancerInfo, 
  RocketTabs, 
  Team, 
  Actor,
  ScoreBoard,
  catalogue, 
  moduleFactory 
};