import React, { useEffect } from 'react';

import { IonProgressBar, IonContent } from '@ionic/react';

import './ProgressBar.css';

interface Props {
    onAir: boolean;
    onAirSeconds: number;
}

const ProgressBar: React.FC<Props> = ({onAir, onAirSeconds}) => {
    
    const [progress, setProgress] = React.useState(1.0);
    
    let timer: any;
    
    useEffect(() => {
        if (onAir) {
            timer = setInterval(() => {
                setProgress((prevProgress) => {
                    const newValue = prevProgress + -(1/(onAirSeconds*10));
                    if (newValue <= 0) {
                        clearInterval(timer)
                    }
                    return newValue;
                })
            }, 100);
        } else {
            clearInterval(timer)
            setProgress(1.0)
        }
    }, [onAir]);
  
    if (onAir && progress > 0) {
        return (
            <div className="webrtc-queue-progress-bar-container">
                <IonProgressBar
                    className="webrtc-queue-progress-bar webrtc-queue-progress-bar-color"
                    color="primary"
                    type="determinate"
                    value={progress} />
            </div>
        )
    } else {
        return null
    }
}

export default ProgressBar;