import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useGA4React } from "ga-4-react";
import ReactPixel from 'react-facebook-pixel';
import { IonApp, IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonLabel, IonMenu, IonMenuButton, IonMenuToggle, IonRouterOutlet, IonTabBar, IonTabButton, IonTabs, IonText, IonTitle, IonToolbar } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { listOutline, home, build, people, close, rocket } from 'ionicons/icons'
import Home from './pages/Home';
import BuilderPage from './pages/BuilderPage';
import RocketPage from './pages/RocketPage';
import PlayerPage from './pages/PlayerPage';
import LoginPage from './pages/auth/LoginPage';



import Nav from './components/Nav';
import FriendsDrawer from './components/FriendsDrawer';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';

import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';

import UserProvider from './providers/UserProvider';

import { StoreProvider, createStore, useStoreState } from 'easy-peasy';
import { Provider } from 'react-redux';

import model from './model';

const store = createStore(model);

function PublicRoute({ component: Component, authenticated, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => authenticated === false
        ? <Component {...props} />
        : <Redirect to='/p/910rocket/party' />}
    />
  )
}

function PrivateRoute({ component: Component, authenticated, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => authenticated === true
        ? <Component {...props} />
        : <Redirect to={{ pathname: '/login' }} />}
    />
  )
}

const App = () => {

  const loading = useStoreState(x => x.auth.loading);
  const authenticated = useStoreState(x => x.auth.authenticated);

  const ga = useGA4React();
  
  // const user = useStoreState((x) => x.auth.user);


  ReactPixel.init(
    process.env.REACT_APP_FACEBOOK_PIXEL, 
    {
      // advanced matching
      // em: user.email
    }, 
    {
      // options
      autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
      debug: false, // enable logs
    });
  // ReactPixel.revokeConsent();
  ReactPixel.pageView();


  if (loading) {
    return (<div>Loading...</div>);
  }

  return (
    <IonReactRouter>
      {/* <FriendsDrawer /> */}
      <PrivateRoute path="/home" authenticated={authenticated} component={Home} exact={true} />
          
      {/* <IonTabs> */}
        <IonRouterOutlet id="main">
          <PublicRoute path="/login" authenticated={authenticated} component={LoginPage} exact={true} />
          <Route path="/builder" component={BuilderPage} exact={true} />
          <Route path="/910rocket" component={RocketPage} exact={true} />
          {/*<PrivateRoute path="/e/:roomId" authenticated={authenticated} component={BuilderPage} />*/}
          <PrivateRoute path="/p/:appId/:roomId" authenticated={authenticated} component={PlayerPage} />
          <Route exact path="/" render={() => <Redirect to="/p/910rocket/party" />} />
        </IonRouterOutlet>
{/* 
        <IonTabBar slot="top">
          <IonTabButton className="friends-tab">
            <IonMenuButton tab="friends">
              <IonIcon icon={people} />
            </IonMenuButton>
          </IonTabButton>

          <IonTabButton tab="home" href="/home">
            <IonIcon icon={home} />
            <IonLabel>Home</IonLabel>
          </IonTabButton>

          <IonTabButton tab="builder" href="/builder">
            <IonIcon icon={build} />
            <IonLabel>Builder</IonLabel>
          </IonTabButton>

          <IonTabButton tab="910rocket" href="/910rocket/party">
            <IonIcon icon={rocket} />
            <IonLabel>910Rocket</IonLabel>
          </IonTabButton>
        </IonTabBar>
      </IonTabs> */}
    </IonReactRouter>
  );
}

export default App;
