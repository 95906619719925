import { action, thunk } from 'easy-peasy';


const friendsModel = {
    
    isLoading: false,
    friendsClient: null,
    connectionState: 'closed',
    friends: [],
    friendRequests: [],
    friendsCount: 0,
    acceptedRequestCount: 0,
    friendRequestCount: 0,
    totalCount: 0,
 
    fetchFriends: action((state, payload) => {
      if (!state.friendsClient) { return; }

      try {
        state.friendsClient.fetchFriends();
      } catch (e) {
        console.log(e);
        // actions.setError(e); TODO
      }
    }),

    acceptFriendRequest: action((state, targetUid) => {
      if (!state.friendsClient) { return; }

      try {
        state.friendsClient.acceptFriendRequest({targetUid});
      } catch (e) {
        console.log(e);
        // actions.setError(e); TODO
      }
    }),

    rejectFriendRequest: action((state, targetUid) => {

      if (!state.friendsClient) { return; }

      try {
        state.friendsClient.rejectFriendRequest({targetUid});
      } catch (e) {
        console.log(e);
        // actions.setError(e); TODO
      }
    }),

    createFriendRequest: action((state, targetUid) => {
      if (!state.friendsClient) { return; }

      try {
        state.friendsClient.createFriendRequest({targetUid});
      } catch (e) {
        console.log(e);
        // actions.setError(e); TODO
      }
    }),

    deleteFriendRequest: action((state, targetUid) => {
      if (!state.friendsClient) { return; }

      try {
        state.friendsClient.deleteFriendRequest({targetUid});
      } catch (e) {
        console.log(e);
        // actions.setError(e); TODO
      }
    }),

    setFriends: action((state, friends) => {
      state.friends = friends;
      state.friendsCount = state.friends.length;
      
    }),

    setFriendRequests: action((state, friendRequests) => {
      state.friendRequests = friendRequests;
      state.friendRequestCount = friendRequests.length;
      
    }),

    setIsLoading: action((state, isLoading) => {
      state.isLoading = isLoading;
    }),

    setFriendCounts: action((state, payload) => {
      state.friendRequestCount = payload.friendRequestCount;
      state.friendsCount = state.friends.length;
      state.acceptedRequestCount = payload.acceptedRequestCount;
      state.totalCount = payload.friendRequestCount + payload.acceptedRequestCount;
    }),


    setFriendsClient: action((state, friendsClient) => {
      state.friendsClient = friendsClient;
    }),
    setConnectionState: action((state, connectionState) => {
      state.connectionState = connectionState;
    }),
};

export default friendsModel;