import protooClient from 'protoo-client';
import axios from 'axios';

//import Logger from './Logger';
//import { getRoomClientUrl } from './urlFactory';
//const logger = new Logger('WebrtcClient');

import { getJoinTokenApiUrl, getGameStateApiUrl, getTouchApiUrl } from '../../urlFactory';

export default class RoomClient
{

	constructor(
		{
			storeActions,
			appId,
			roomId,
			peerId,
			peerDisplayName
		}
	)
	{
		this._storeActions = storeActions;

		// Closed flag.
		// @type {Boolean}
		this._closed = false;

		// Peer id.
		// @type {Number}
		this._peerId = peerId;
		this._peerDisplayName = peerDisplayName;

		// Peer id.
		// @type {Number}
		this._roomId = roomId;

		// Peer id.
		// @type {Number}
		this._appId = appId;

		// Protoo URL.
		// @type {String}
		this._protooUrl = getGameStateApiUrl({ appId, roomId, peerId });

		// protoo-client Peer instance.
		// @type {protooClient.Peer}
		this._protoo = null;

		this._joining = false;
		//this.join();

		this._touchTimer = null;
	}

	setDisplayName(displayName) {
		this._peerDisplayName = displayName;
	}

	close()
	{
		if (this._closed)
			return;

		this._closed = true;

		//logger.debug('close()');

		// Close protoo Peer
		this._protoo.close();

		this._storeActions.setConnectionState('closed');

		if (this._touchTimer) {
			clearInterval(this._touchTimer);
		}
	}

	async touch() {
		axios.get(getTouchApiUrl({ appId: this._appId, roomId: this._roomId, peerId: this._peerId }))
			.then(function (res) {
				//console.log(res);
			})
			.catch(function (err) {
				//console.log(err);
			});
	}

	async awaitJoinToken(joinData, callback) {
		axios.get(getJoinTokenApiUrl({ appId: this._appId, roomId: this._roomId, peerId: this._peerId }))
			.then(function (res) {
				console.log(res.data);
				if (res.data.status == 'ok') {
					this._joining = false;
					callback(joinData);
				} else {
					this._storeActions.setWaitingRoomRank(res.data.rank);
					setTimeout(() => {
						this.awaitJoinToken(joinData, callback);
					}, 15000);
				}
			}.bind(this))
			.catch(function (err) {
				console.log(err);
			});
	}

	async join(joinData)
	{
		if (!this._joining) {
			this._joining = true;
			this.awaitJoinToken(joinData, this._realJoin.bind(this));
		}
	}

	async _realJoin(joinData)
	{
		if (joinData && joinData.displayName) {
			this._peerDisplayName = joinData.displayName;
		} else {
			joinData = { ...joinData, displayName: this._peerDisplayName };
		}

		const protooTransport = new protooClient.WebSocketTransport(this._protooUrl);

		this._protoo = new protooClient.Peer(protooTransport);

		this._storeActions.setConnectionState('connecting');

		this._protoo.on('open', () => this._joinRoom(joinData));

		this._protoo.on('failed', () =>
		{
			this._storeActions.notify(
				{
					type : 'error',
					text : 'WebSocket connection failed'
				});
		});

		this._protoo.on('disconnected', () =>
		{
			this._storeActions.notify(
				{
					type : 'error',
					text : 'WebSocket disconnected'
				});

			this._storeActions.setConnectionState('closed');
		});

		this._protoo.on('close', () =>
		{
			if (this._closed)
				return;

			this.close();
		});

		this._protoo.on('notification', (notification) =>
		{
			switch (notification.method)
			{
				case 'roomState':
				{
					this._storeActions.setState(notification.data);
					break;
				}

				default:
				{
					//logger.error(
					//	'unknown protoo notification.method "%s"', notification.method);
				}
			}
		});
		this._touchTimer = setInterval(() => {
			this.touch();
		}, 30000);
	}

	async _joinRoom(joinData) {
		try {
			// Join now into the room.
			// NOTE: Don't send our RTP capabilities if we don't want to consume.
			const { roomState } = await this._protoo.request('join', joinData);

			this._storeActions.setConnectionState('connected');
			this._storeActions.setIsJoined(true);

			if (roomState) {
				this._storeActions.setState(roomState);
			}
		}
		catch (error)
		{
			console.log(error);
			//logger.error('_joinRoom() failed:%o', error);
			this.close();
		}
	}

	async emit(payload) {
		try {
			const { roomState } = await this._protoo.request('emit', payload);
			//this._storeActions.setState(roomState);
		}
		catch (error)
		{
			console.log(error);
		}
	}

}
