import React, { useState, useRef, SetStateAction, useEffect } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import validUrl from 'valid-url';
import { IonTextarea, IonSegment, IonSegmentButton, IonContent, IonButtons, IonButton, IonIcon, IonCol, IonGrid, IonInput, IonItem, IonLabel, IonList, IonRow, IonText } from '@ionic/react';
import { refreshCircleOutline } from 'ionicons/icons';
import './EditorView.css';

import axios from 'axios';
import { getStreamingApiUrl } from '../../../urlFactory';

interface EditorViewProps {
  i: string;
  state: any;
  setIsEditorOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const EditorView: React.FC<EditorViewProps> = (props) => {

  const [motd, setMotd] = useState('');
  
  const setElementState = useStoreActions((s: any) => s.room.setElementState);

  useEffect(() => {
    if (props.state) {
      setMotd(props.state.motd);
    }
  }, [props.state]);

  const saveHandler = () => {
    setElementState({
      i: props.i,
      state: {
        motd,
      }
    });
    props.setIsEditorOpen(false);
  }

  return (
    <IonGrid className="editor-view-container" draggable={false}>
      <IonRow>
        <IonCol>
          <strong>Chat Module Editor View</strong>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol>
          <IonList className="ion-padding">
            <IonItem>
              <IonLabel position="floating">Message of the day</IonLabel>
              <IonTextarea value={motd} onIonChange={e => setMotd(e.detail.value!)}></IonTextarea>
            </IonItem>
          </IonList>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol>
          <IonButton expand="block" onClick={() => { props.setIsEditorOpen(false) }}>Cancel</IonButton>
        </IonCol>
        <IonCol>
          <IonButton color="light" expand="block" onClick={saveHandler}>Save</IonButton>
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

export default EditorView;