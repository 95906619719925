import React, { useState, useEffect, useMemo } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { IonInput, IonItem, IonLabel, IonList, IonSearchbar } from '@ionic/react';
import Webcam from "react-webcam";
import Async, { makeAsyncSelect } from 'react-select/async';
import AsyncSelect from 'react-select/async';
import './Actor.scss';

import ReactPlayer from 'react-player'

import Me from '../../WebrtcClient/Me';
import Peer from '../../WebrtcClient/Peer';

interface ActorProps {
    state: any;
}

const Actor: React.FC<ActorProps> = props => {

    const user = useStoreState((store: any) => store.auth.user);
    const [options, setOptions] = useState<any>(null);
    const emit = useStoreActions((s: any) => s.room.emit);

    const startedAt = useStoreState((store: any) => store.room.state?.commonState?.startedAt);
    const gameState = useStoreState((store: any) => store.room.state?.commonState?.gameState);
    const teamWord = useStoreState((store: any) => store.room.state?.teamState?.word);
    const currentTeamId = useStoreState((store: any) => store.room.state?.commonState?.currentTeamId);
    const currentPlayerId = useStoreState((store: any) => store.room.state?.commonState?.currentPlayerId);
    const currentTeam = useStoreState((store: any) => store.room.state?.commonState?.teams[currentTeamId]);

    const privateState = useStoreState((store: any) => store.room.state?.privateState);

    const [videoUrl, setVideoUrl] = useState<any>(null);
    const [videoStartPosition, setVideoStartPosition] = useState<number>(0);
    const [isSolved, setIsSolved] = useState<boolean>(false);
    const [isStarted, setIsStarted] = useState<boolean>(false);
    const [isMyTeam, setIsMyTeam] = useState<boolean>(false);
    
    function handleGuess(guess: any)  {
        if (!guess) { return }
        emit({ event: 'guess', payload: { guess } });
    }

    function fetchOptions() {
        if (props.state && props.state.optionsUrl) {
            fetch(props.state.optionsUrl)
                .then(res => res.json())
                .then(res => {
                    setOptions(res.map((el: any) => {
                        if (typeof el == 'string') {
                            return { value: el, label: el };
                        } else {
                            return el;
                        }
                    }));
                })        
        }
    }

    useEffect(() => {
        fetchOptions();
    }, []);

    useEffect(() => {

        const isMyTeam = currentTeam && currentTeam.playerIds && currentTeam.playerIds.includes(user.uid);
        setIsMyTeam(isMyTeam); 

        setIsStarted(gameState == 'started');
        
        let isSolved = false;
            
        if (isMyTeam && teamWord) {
            setVideoUrl(teamWord.videoUrl);
            setVideoStartPosition(0);
        } else {
            if (!isMyTeam && privateState && privateState.word && privateState.word.videoUrl) {
                if (privateState.timestamp &&startedAt) {
                    setVideoStartPosition(Math.ceil((privateState.timestamp - startedAt) / 1000) + 1);
                }
                setVideoUrl(privateState.word.videoUrl);
                isSolved = true;
            } else {
                setVideoUrl(null);
            }
        }
        setIsSolved(isSolved);
    }, [currentTeam, currentPlayerId]);

    const CustomClearText: React.FC = () => <span>clear</span>
    const ClearIndicator = (props: any) => {
        const {
            children = <CustomClearText />,
            getStyles,
            innerProps: { ref, ...restInnerProps },
        } = props;
        return (
            <div
                {...restInnerProps}
                ref={ref}
                style={getStyles('clearIndicator', props)}
            >
                <div style={{ padding: '0px 5px' }}>{children}</div>
            </div>
        );
    };

    const BigVideo = (props: any) => {
        const {
            uid,
        } = props;
        const isMe = (uid == user.uid);
        if (isMe) {
            return (<Me width={'100%'} height={'100%'} />);
        } else {
            return (<Peer width={'100%'} height={'100%'} id={uid} />);
        }
    };

    const PadAudio = (props: any) => {
        const {
            enabled
        } = props;
        if (enabled) {           
            return (
                <audio className="pad-audio" loop autoPlay>
                  <source src="/assets/misc/pad.mp3"></source>
                </audio>
            );
        } else {
            return null;
        }
    };

    const MainScreen = (props: any) => {

        const { videoUrl, videoUid, videoStartPosition } = props;
        
        if (videoUrl) {
            return (
                <ReactPlayer
                  playsinline={true}
                  muted={false}
                  className="hls-video-wrapper"
                  url={videoUrl}
                  width="100%"
                  height="100%"
                  playing={true}
                  config={
                    {
                      file: {
                        hlsOptions: {
                          autoStartLoad: true,
                          liveSyncDuration: 1, liveMaxLatencyDuration: 4,
                          startPosition: videoStartPosition
                        }
                      }
                    }
                  } />
            );
        }

        if (videoUid) {
            return (<BigVideo uid={ videoUid } />);
        }

        return null;
    }

    const GuessSelector = (props: any) => {
        const {
            options,
            onSelect
        } = props;

        const [selectValue, setSelectValue] = useState<any>(null);

        function handleSelectValueChange(el: any) {
            if (el) {
                onSelect(el.value);
                setSelectValue(null);
            }
        }

        function loadOptions(inputValue: string, callback: any) {

            if (options !== null) {
                const regex = RegExp(inputValue.replace("-", "").replace(" ", ""), "i");
                callback(options.filter((option: any) => {
                    return regex.test(option.label); 
                }));
            }
        }

        return (
            <AsyncSelect
                    cacheOptions
                    autoFocus={true}
                    value={selectValue}
                    components={{ ClearIndicator }}
                    loadOptions={loadOptions}
                    //onInputChange={handleInputChange}
                    onChange={(value) => handleSelectValueChange(value)}
                    isClearable={true}
                />
        );
    };

    const memoizedMainScreen = useMemo(() => {
        return (<MainScreen videoUrl={videoUrl} videoStartPosition={videoStartPosition} videoUid={currentPlayerId} />);
    }, [currentPlayerId, videoUrl, videoStartPosition]);


    return (
        <div className="lbb-actor">
            <div className="actor-video add-shadow">
            { memoizedMainScreen }
            </div>
            { isStarted ?
                (isMyTeam ?
                    (
                        <div style={{position: 'absolute', top: '20px', left: '20px', color: 'white'}}>
                            Your teams word: { teamWord ? teamWord.label : '-' }
                            <br />
                            Game state: { gameState ? gameState : '-' }
                        </div>
                    ) 
                :
                    (!isSolved ? 
                        (<div className="guess-field">
                            <GuessSelector options={options} onSelect={handleGuess}/>
                            <PadAudio enabled={!isSolved} />
                        </div>)
                        : (<div>You solved it!</div>)
                    )
                )
                :
                null
            }
            
        </div>
    )
}

export default Actor