import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import GA4React, { useGA4React } from "ga-4-react";


const ga4react = new GA4React(process.env.GOOGLE_ANALYTICS!);
(async () => {
    await ga4react.initialize().then((g4) => {
        g4.pageview('path');
        g4.gtag('event','pageview','path');
    }, (err) => {
        console.log(err);
    });
    ReactDOM.render(<App />, document.getElementById('root'));
})();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

