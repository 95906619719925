import React, { useContext, useState, useEffect, useRef, useCallback } from 'react';
import { useMutationObserver } from '../../../hooks';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { IonAvatar, IonButton, IonChip, IonCol, IonGrid, IonIcon, IonInput, IonItem, IonLabel, IonModal, IonPopover, IonRow, IonText, IonTextarea } from '@ionic/react';
import { UserContext, UserContextValue } from '../../../providers/UserProvider';
import 'emoji-mart/css/emoji-mart.css'
import { Picker } from 'emoji-mart'
import Avatar from '../../Common/Avatar'

import { saveMessage, loadMessages } from './chatService';
import { convertCompilerOptionsFromJson } from 'typescript';
import { closeOutline, openOutline } from 'ionicons/icons';
import './DisplayView.css';
interface DisplayViewProps { };

const DisplayView: React.FC<DisplayViewProps> = (props: any) => {

  const showUserProfile = useStoreActions((s: any) => s.userModal.showUserProfile);
  const roomId = useStoreState((s: any) => s.room.roomId);

  const [messages, setMessages] = useState<any[]>([]);

  const [text, setText] = useState<string>("");
  const user = useContext<UserContextValue>(UserContext);

  const [showEmojiPicker, setShowEmojiPicker] = useState<boolean>(false)

  const node = useRef(null);

  useEffect(() => {
    const newMessage = (message: any) => {
      setMessages(messages => [...messages, message]);
    };
    loadMessages(roomId, newMessage);
  }, [roomId]);

  const handleMutations = useCallback((mutations) => {
    mutations.forEach(
      ({
        type,
        target
      }: {
        type: MutationRecordType;
        target: Element | null;
      }) => {
        //requestAnimationFrame(
        //  () => {
        setTimeout(() => {
          target?.scroll({ top: target.scrollHeight, behavior: 'smooth'});
        }
        , 100);
        //  }
        //);
      }
    );
  }, []);
  
  useMutationObserver({
    target: node,
    options: { childList: true },
    callback: handleMutations
  });

  const handleSendMessage = (event?: any | undefined) => {
    if (!text || (text.trim() == '')) { return false; }

    const message = {
      userDisplayName: user.displayName,
      userId: user.uid,
      text: text
    };
    saveMessage(roomId, message);
    setText("");
    setShowEmojiPicker(false);
  };

  const handleChangeText = (event: any) => {
    setText(event.target.value);
  };

  const handleKeyDownText = (event: any) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleSendMessage();
      return false;
    }
  };

  const addEmoji = (emoji: any) => {
    let selectedEmoji = emoji.native
    setText((prev) => prev + selectedEmoji)
  }

  const showMotd = () => {
    if (!props.state || !props.state.motd) {
      return null;
    }
    return (
      <IonItem>
        <IonText>
          {props.state.motd}
        </IonText>
      </IonItem>
    );
  };

  return (
    <div className="lbb-chat lbb-container">
      <div className="chat-container" >
        <div className="chat-content" >
          <div className="scrollable-area" ref={node} >
            {showMotd()}
            {
              messages.map((m, i) => {
                return (
                  <IonItem key={i}>
                    <IonText><IonChip className="ion-float-left" onClick={() => showUserProfile(m.userId)}>
                      <Avatar uid={m.userId} />
                      <IonLabel>{m.userDisplayName}</IonLabel>
                    </IonChip><span className="text-wrapper">{m.text}</span></IonText>
                  </IonItem>);
              })
            }
          </div>
        </div>

        <IonGrid className="button-grid">
          <IonRow>
            <IonCol>
              <IonTextarea className="chat-text-area ion-text-left" name="text" rows={1} autoGrow={false} placeholder="How is your day?" value={text} onKeyDown={(event) => handleKeyDownText(event)} onIonChange={(event) => handleChangeText(event)} />
            </IonCol>
            {/* {
          showEmojiPicker &&
          <div className="emoji-popover">
            <IonButton size="small" onClick={() => setShowEmojiPicker(false)}>
              <IonIcon slot="icon-only" icon={closeOutline} />
            </IonButton>
            <Picker onSelect={addEmoji} />
          </div>
        } */}


            {/* <IonCol>
              {
                showEmojiPicker ?
                <IonButton size="small" expand="block" onClick={() => setShowEmojiPicker(false)}> <IonIcon slot="start" icon={closeOutline} />Close</IonButton>
                : <IonButton size="small" onClick={() => setShowEmojiPicker(true)}><IonIcon slot="start" icon={openOutline} />Emoji catalogue</IonButton>
              }
              
            </IonCol> */}
            <IonCol className="ion-text-right" size="auto">
              <IonButton className="chat-button" size="small" onClick={(event) => handleSendMessage(event)}>Send</IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </div>
    </div>
  );
};

export default DisplayView;
