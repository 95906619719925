import React, { useState } from 'react';
import Lottie from 'react-lottie';
import heartLottieData from './heart-animation.json';


const FriendButton: React.FC = () => {

    const [heartAnimationIsStopped, setHeartAnimationIsStopped] = useState(true);

    // function handleSendFriendRequest() {
    //     setHeartAnimationIsStopped(false);
    //     if (currentDancer != null) {
    //         if (currentDancer.userId != me.userId) {
    //             sendFriendRequest({ sourceUserId: me.userId, targetUserId: currentDancer.userId, sourceDisplayName: me.displayName, targetDisplayName: currentDancer.displayName});
    //         } else {
    //             enqueueSnackbar('You cannot add yourself as friend :)');
    //         }
    //     }
    // }

    const lottieOptions = {
        loop: false,
        autoplay: false,
        animationData: heartLottieData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    }

    return (
        <Lottie 
        options={lottieOptions}
        height={60}
        width={60}
        isClickToPauseDisabled={true}
        isStopped={heartAnimationIsStopped}
    />

    )
}

export default FriendButton