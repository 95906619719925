import React from 'react';
import { IonAvatar } from '@ionic/react';
import './IsOnlineBadge.css'

const IsOnlineBadge: React.FC<{size?: string, noRipple?: boolean, header?: boolean}> = (props) => {

    return (
        <span className={`is-online-badge ${props.size === "large" ? "large-badge": null } ${props.header ? " is-header" : null} ${props.noRipple ? " has-no-ripple" : null}`}></span>
    )
}

export default IsOnlineBadge;