import { action, thunk, computed } from 'easy-peasy';
import axios from 'axios';
import { auth } from '../firebase';
import { getProfileApiUrl } from '../urlFactory'


const calculateAge = (birthday) => { // birthday is a date
    var ageDifMs = Date.now() - birthday.getTime();
    var ageDate = new Date(ageDifMs); // miliseconds from epoch
    return Math.abs(ageDate.getUTCFullYear() - 1970);
}

const userModalModel = {

    userProfile: null,
    isOpen: false,
    loading: true,
    uid: null,

    age: computed(state => (state.userProfile && state.userProfile.dateOfBirth ? calculateAge(new Date(state.userProfile.dateOfBirth)) : null)),

    setUid: action((state, uid) => {
      state.uid = uid;
    }),

    setIsOpen: action((state, isOpen) => {
      state.isOpen = isOpen;
    }),

    setLoading: action((state, loading) => {
      state.loading = loading;
    }),

    setUserProfile: action((state, payload) => {
      state.userProfile = payload;
    }),

    showUserProfile: thunk(async (actions, uid) => {
      actions.setLoading(true);
      actions.setIsOpen(true);
      actions.setUid(uid);
      axios.get(getProfileApiUrl('/show?uid='+uid))
        .then(res => {
          actions.setUserProfile({
            ...res.data.profile,
            instagram_media: res.data.instagram_media,
            isFriend: res.data.isFriend,
            isFriendRequestSent: res.data.isFriendRequestSent,
            isFriendRequestReceived: res.data.isFriendRequestReceived

          });
          actions.setLoading(false);
        })
      	.catch(err => err);
    })
};

export default userModalModel;