import React, { useState, useRef, SetStateAction, useEffect } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import validUrl from 'valid-url';
import { IonSegment, IonSegmentButton, IonContent, IonButtons, IonButton, IonIcon, IonCol, IonGrid, IonInput, IonItem, IonLabel, IonList, IonRow, IonText } from '@ionic/react';
import { refreshCircleOutline } from 'ionicons/icons';
import './HlsVideo.css';
import './EditorView.css';

import axios from 'axios';
import { getStreamingApiUrl } from '../../../urlFactory';

interface EditorViewProps {
  i: string;
  state: any;
  setIsEditorOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const EditorView: React.FC<EditorViewProps> = (props) => {

  const [url, setUrl] = useState('');
  const [upstreamUrl, setUpstreamUrl] = useState('');
  const [downstreamUrl, setDownstreamUrl] = useState('');
  const [type, setType] = useState('ownStream');
  const [streamUrl, setStreamUrl] = useState('');
  const [streamKey, setStreamKey] = useState('');
  const [title, setTitle] = useState('');
  const [error, setError] = useState('');
  const [selectedSegment, setSelectedSegment] = useState('ownStream');

  const setElementState = useStoreActions((s: any) => s.room.setElementState);

  useEffect(() => {
    setTitle(props.state.title);
    setUrl(props.state.downstreamUrl);
    setUpstreamUrl(props.state.upstreamUrl);
    setDownstreamUrl(props.state.downstreamUrl);
    setType(props.state.type);

  }, [props.state]);

  useEffect(() => {
    if (upstreamUrl) {
      const str = upstreamUrl;
      setStreamUrl(str.substring(0, str.lastIndexOf("/") + 1));
      setStreamKey(str.substring(str.lastIndexOf("/") + 1));
    }
  }, [upstreamUrl]);

  const handleCreateStream = () => {
    axios.get(getStreamingApiUrl('/create'))
      .then((res: any) => {
          setUrl(res.data.downstreamUrl);
          setDownstreamUrl(res.data.downstreamUrl);
          setUpstreamUrl(res.data.upstreamUrl);
        })
      .catch(err => {});
  };

  const saveHandler = () => {
    setElementState({
      i: props.i,
      state: {
        title: title ?? '',
        type: 'hls',
        upstreamUrl: upstreamUrl ?? '',
        downstreamUrl: downstreamUrl ?? '',
        url: url ?? ''
      }
    });
    props.setIsEditorOpen(false);
  }

  const changeSegmentHandler = (e: any) => {
    setType(e.detail.value);
    setSelectedSegment(e.detail.value);
  }

  const renderOwnStreamSegment = () => {
    if (selectedSegment !== 'ownStream') { return null; }
    return (
      <div>
        <React.Fragment>
          <IonList className="ion-padding">
            <IonItem>
              <IonLabel position="floating">Title</IonLabel>
              <IonInput type="text" value={title} onInput={(e: any) => setTitle(e.target.value)} />
            </IonItem>
            <IonItem lines="none">
                <IonButton onClick={(e) => handleCreateStream()} >
                  <IonIcon icon={refreshCircleOutline} title="New stream" />
                  Get stream key
                </IonButton>
            </IonItem>
            <IonItem>
              <IonLabel position="floating">Stream URL (RTMP ingress)</IonLabel>
              <IonInput disabled type="text" value={streamUrl} />
            </IonItem>
            <IonItem>
              <IonLabel position="floating">Stream key</IonLabel>
              <IonInput disabled type="text" value={streamKey} />
            </IonItem>
           </IonList>
 
        </React.Fragment>
      </div>
    );
  }

  const renderUrlSegment = () => {
    if (selectedSegment !== 'url') { return null; }
    return (
      <div>
        <React.Fragment>
          <IonList className="ion-padding">
            <IonItem>
              <IonLabel position="floating">Title</IonLabel>
              <IonInput type="text" value={title} onInput={(e: any) => setTitle(e.target.value)} />
            </IonItem>
          </IonList>
        </React.Fragment>
      </div>
    );
  }


  return (
    <IonGrid className="editor-view-container" draggable={false}>
      <IonRow>
        <IonCol>
          <strong>HLS Video Module Editor View</strong>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol>
              <IonContent style={{height: 300}}>
                <IonSegment value={selectedSegment} onIonChange={changeSegmentHandler}>
                  <IonSegmentButton value="ownStream">
                    <IonLabel>Own stream</IonLabel>
                  </IonSegmentButton>
                  <IonSegmentButton value="url">
                    <IonLabel>URL</IonLabel>
                  </IonSegmentButton>
                </IonSegment>
                { renderOwnStreamSegment() }
                { renderUrlSegment() }
              </IonContent>
        </IonCol>
      </IonRow>
      { error &&
        <IonRow>
          <IonCol>
            <IonText color="danger">
              <p>{error}</p>
            </IonText>
          </IonCol>
        </IonRow>
      }
      <IonRow>
        <IonCol>
          <IonButton expand="block" onClick={() => { props.setIsEditorOpen(false) }}>Cancel</IonButton>
        </IonCol>
        <IonCol>
          <IonButton color="light" expand="block" onClick={saveHandler}>Save</IonButton>
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

export default EditorView;