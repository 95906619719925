import React from 'react';
import ReactPlayer from 'react-player'

import './DisplayView.css';

interface DisplayViewProps { }

const DisplayView: React.FC<DisplayViewProps> = () => {

  const url = "https://www.youtube.com/watch?v=TUVcZfQe-Kw"

  return (
    <div className="display-container">

      {
        url ? <ReactPlayer className="" url={url} width="inherit" height="inherit" />
          : <strong>YouTube Video Module Display View</strong>
      }

    </div>
  );
};

export default DisplayView;