import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import classnames from 'classnames';
import * as cookiesManager from './cookiesManager';
import * as appPropTypes from './appPropTypes';


import { WebrtcContext } from './WebrtcClientProvider';
import PeerView from './PeerView';

class Me extends React.Component
{
	constructor(props)
	{
		super(props);
	}

	render()
	{
		const {
			webrtcClient,
			connected,
			me,
			audioProducer,
			videoProducer,
			faceDetection,
			onSetStatsPeerId
		} = this.props;

		let showControls = false;

		let micState;

		if (!me.canSendMic)
			micState = 'unsupported';
		else if (!audioProducer)
			micState = 'unsupported';
		else if (!audioProducer.paused)
			micState = 'on';
		else
			micState = 'off';

		let webcamState;

		if (!me.canSendWebcam)
			webcamState = 'unsupported';
		else if (videoProducer && videoProducer.type !== 'share')
			webcamState = 'on';
		else
			webcamState = 'off';

		let changeWebcamState;

		if (Boolean(videoProducer) && videoProducer.type !== 'share' && me.canChangeWebcam)
			changeWebcamState = 'on';
		else
			changeWebcamState = 'unsupported';

		let shareState;

		if (Boolean(videoProducer) && videoProducer.type === 'share')
			shareState = 'on';
		else
			shareState = 'off';

		const videoVisible = Boolean(videoProducer) && !videoProducer.paused;

		return (
			<div
				data-component='Me'>

				<PeerView
					isMe={true}
					peer={me}
					audioProducerId={audioProducer ? audioProducer.id : null}
					videoProducerId={videoProducer ? videoProducer.id : null}
					audioRtpParameters={audioProducer ? audioProducer.rtpParameters : null}
					videoRtpParameters={videoProducer ? videoProducer.rtpParameters : null}
					audioTrack={audioProducer ? audioProducer.track : null}
					videoTrack={videoProducer ? videoProducer.track : null}
					videoVisible={videoVisible}
					audioCodec={audioProducer ? audioProducer.codec : null}
					videoCodec={videoProducer ? videoProducer.codec : null}
					audioScore={audioProducer ? audioProducer.score : null}
					videoScore={videoProducer ? videoProducer.score : null}
					faceDetection={faceDetection}
					onChangeMaxSendingSpatialLayer={(spatialLayer) =>
					{
						webrtcClient.setMaxSendingSpatialLayer(spatialLayer);
					}}
					onStatsClick={onSetStatsPeerId}
					onBroadcastClick={ () =>
					{
						webrtcClient.broadcastMe()
					}}
				/>

			</div>
		);
	}

}

Me.propTypes =
{
	webrtcClient       : PropTypes.any.isRequired,
	connected        : PropTypes.bool.isRequired,
	me               : appPropTypes.Me.isRequired,
	audioProducer    : appPropTypes.Producer,
	videoProducer    : appPropTypes.Producer,
	faceDetection    : PropTypes.bool.isRequired,
	onSetStatsPeerId : PropTypes.func.isRequired
};

const mapStateToProps = (state) =>
{
	const producersArray = Object.values(state.webrtcClient.producers.producers);
	const audioProducer =
		producersArray.find((producer) => producer.track.kind === 'audio');
	const videoProducer =
		producersArray.find((producer) => producer.track.kind === 'video');

	return {
		connected     : state.webrtcClient.state === 'connected',
		me            : state.webrtcClient.me,
		audioProducer : audioProducer,
		videoProducer : videoProducer,
		faceDetection : false, //state.webrtcClient.room.faceDetection
		webrtcClient  : state.webrtcClient
	};
};

const mapDispatchToProps = (dispatch) =>
{
	return {
		onSetStatsPeerId: () => {}
		//onSetStatsPeerId : (peerId) => dispatch(stateActions.setRoomStatsPeerId(peerId))
	};
};

const MeContainer = connect(
	mapStateToProps,
	mapDispatchToProps
)(Me);

export default MeContainer;
