import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import React, { useEffect } from 'react';
import { withRouter } from 'react-router';
import { useStoreActions, useStoreState } from 'easy-peasy';
import DragAndDropBuilder from '../components/DragAndDropBuilder';
import Header from '../components/Header';
import './BuilderPage.css';

import RoomClientProvider from '../components/RoomClient/RoomClientProvider';
import WebrtcClientProvider from '../components/WebrtcClient/WebrtcClientProvider';
import FriendsClientProvider from '../components/FriendsClient/FriendsClientProvider';
import TopRightFab from '../components/TopRightFab';

const BuilderPage: React.FC = (props: any) => {

  const roomId = useStoreState((store: any) => store.room.roomId);
  const setRoomId = useStoreActions((store: any) => store.room.setRoomId);
  const getLayoutById = useStoreActions((store: any) => store.room.getLayoutById);
  const user = useStoreState((store: any) => store.auth.user);
  const setIsAdmin = useStoreActions((store: any) => store.room.setIsAdmin);
  const setRoomParams = useStoreActions((store: any) => store.room.setRoomParams);  

  useEffect(() => {
    const rId = props.match.params.roomId;

    let aId = null;
    if (props.match.params.appId) {
      aId = props.match.params.appId;
    } else {
      aId = rId;
    }

    setIsAdmin(true);
    // setRoomId(rId);
    setRoomParams({roomId: rId, appId: aId });
    getLayoutById(rId);
  }, []);

  return (
    <RoomClientProvider roomId={roomId}>
      <WebrtcClientProvider roomId={roomId}>
        <FriendsClientProvider roomId={roomId} user={user}>
          <IonPage>
              <Header/>
             
            <IonContent fullscreen>
            <TopRightFab/>
              {/* <IonHeader collapse="condense">
                <IonToolbar>
                  <IonTitle size="large">LaBoum Builder</IonTitle>
                </IonToolbar>
              </IonHeader> */}
              <DragAndDropBuilder />
            </IonContent>
          </IonPage>
        </FriendsClientProvider>
      </WebrtcClientProvider>
    </RoomClientProvider>  
  );
};

export default withRouter(BuilderPage);
