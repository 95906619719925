import React from 'react';
import { IonCol, IonGrid, IonRow, IonText } from '@ionic/react';


const CameraAndVoice: React.FC = () => {
    return (
        <IonGrid>
            <IonRow className="board-title">
                <IonCol>
                    <IonText>
                        <h2>CAMERA AND VIDEO</h2>
                    </IonText>
                </IonCol>
            </IonRow>
        </IonGrid>
    )
}

export default CameraAndVoice;
