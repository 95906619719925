import React from 'react';
import { useStoreState } from 'easy-peasy';
import './ScoreBoard.scss';

interface ScoreBoardProps {
    //home: number;
    //away: number;
}
const ScoreBoard: React.FC<ScoreBoardProps> = () => {

    const commonState = useStoreState((store: any) => store.room.state?.commonState);
    const home = commonState ? commonState.teams['team1'].score : 0;
    const away = commonState ? commonState.teams['team2'].score : 0;

    return (
        <div className="lbb-scoreboard">
            <div className="score">
                <span>{home}</span>
            </div>
            <div className="score">
                <span>{away}</span>
            </div>
        </div>
    )
}

export default ScoreBoard;