import React from "react";
import { useStoreActions, useStoreState } from 'easy-peasy';
import { IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonModal, IonTitle, IonToolbar } from "@ionic/react";
import { closeOutline } from "ionicons/icons";
import Tabs from 'react-responsive-tabs';
import Profile from './Profile';
import CameraAndVoice from './CameraAndVoice';
import Billing from './Billing';
import Admin from './Admin';

import 'react-responsive-tabs/styles.css';
import './SettingsDashboard.scss';


const settingsTabs = [
    { title: 'Profile', content: 'profile' },
    { title: 'Camera & Voice', content: 'camera-and-voice' },
    { title: 'Billing', content: 'billing' },
    { title: 'Admin', content: 'admin' },
]

interface SettingsDashboardProps {

}

const SettingsDashboard: React.FC<SettingsDashboardProps> = (props) => {
    const isOpen = useStoreState((s: any) => s.profileModal.isOpen);
    const setIsOpen = useStoreActions((s: any) => s.profileModal.setIsOpen);

    const getTabs = () => {
        return settingsTabs.map((tab, index) => ({
            title: tab.title,
            getContent: () => {
                switch (tab.content) {
                    case 'profile': {
                        return <Profile />;
                        break;
                    }
                    case 'camera-and-voice': {
                        return <CameraAndVoice />;
                        break;
                    }

                    case 'billing': {
                        return <Billing />;
                        break;
                    }

                    case 'admin': {
                        return <Admin />;
                        break;
                    }

                    default: return null;
                }
            },
            key: index,
        }))
    }

    return (
        <IonModal isOpen={isOpen} onDidDismiss={() => setIsOpen(false)} cssClass='settings-dashboard'>
            <IonHeader>
                <IonToolbar>
                    <IonTitle className="ion-text-center">Settings Dashboard</IonTitle>
                    <IonButtons slot="end" className="modal-close-buttons" onClick={() => { setIsOpen(false); }}>
                        <IonButton size="small" className="modal-close-button">
                            <IonIcon icon={closeOutline} slot="icon-only" />
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <Tabs items={getTabs()} tabsWrapperClass="this-is-it" />
            </IonContent>
        </IonModal>
    )

}

export default SettingsDashboard