import React, { useState } from 'react';
import { useStoreState } from 'easy-peasy';
import EditorView from './EditorView';
import DisplayView from './DisplayView';
import { useParams } from 'react-router';
import { IonButton } from '@ionic/react';


const isBuilder = true;
//const isAdmin = true;
const isEditing = true;

const HlsVideo: React.FC = (props: any) => {
    const [isEditorOpen, setIsEditorOpen] = useState(true);
    const isAdmin = useStoreState((store: any) => store.room.isAdmin);
  
    return (
        <div className="lbb-container hls-video">
            <DisplayView {...props} />
            { isBuilder && isAdmin && isEditorOpen &&
                <EditorView  id={props.id} setIsEditorOpen={setIsEditorOpen} {...props} />
            }
            {isAdmin &&
                <IonButton className="btn-open-editor" onClick={() => { setIsEditorOpen(true) }}>
                    Drag {!isEditorOpen && ' / Open Editor'}
                </IonButton>
            }
        </div>
    );
};

export default HlsVideo;
