import { IonButton, IonContent, IonHeader, IonIcon, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { useStoreActions, useStoreState } from 'easy-peasy';
import GridContainer from '../components/GridContainer';
import Canvas from '../components/canvasBackground';
import Header from '../components/Header';
import './PlayerPage.css';
import useWindowDimensions from '../components/utilities/useWindowDimensions';

import RoomClientProvider from '../components/RoomClient/RoomClientProvider';
import WebrtcClientProvider from '../components/WebrtcClient/WebrtcClientProvider';
import FriendsClientProvider from '../components/FriendsClient/FriendsClientProvider';
import TopRightFab from '../components/TopRightFab';

import JoinModal from '../components/JoinModal';
import { star } from 'ionicons/icons';

const PlayerPage: React.FC = (props: any) => {
  const { fabsMenu } = props;
  const roomId = useStoreState((store: any) => store.room.roomId);
  const appId = useStoreState((store: any) => store.room.appId);
  const setRoomParams = useStoreActions((store: any) => store.room.setRoomParams);  
  const getLayoutById = useStoreActions((store: any) => store.room.getLayoutById);  
  const user = useStoreState((store: any) => store.auth.user);
  const setIsAdmin = useStoreActions((store: any) => store.room.setIsAdmin);
  const { height, width } = useWindowDimensions();
  const [ showCanvas, setShowCanvas ] = useState<Boolean>(false)

  useEffect(() => {
    const aId = props.match.params.appId;

    let rId = null;
    if (props.match.params.roomId) {
      rId = props.match.params.roomId;
    } else {
      rId = aId;
    }

    setIsAdmin(false);
    setRoomParams({roomId: rId, appId: aId });
    getLayoutById(aId);
  }, []);

  return (
    <RoomClientProvider roomId={roomId} appId={appId} user={user}>
    <WebrtcClientProvider roomId={roomId} user={user}>
    <FriendsClientProvider roomId={roomId} user={user}>
    <IonPage>
    
      <Header/>
      <JoinModal />
      <IonContent fullscreen>
      <IonButton className="canvas-background-button" style={{position: "absolute", width: 30, height: 30, bottom: 0, left: 0, zIndex: 1000, opacity: 0}} onClick={()=>setShowCanvas(!showCanvas)}>
        <IonIcon slot="start" icon={star} />
      </IonButton>  
      {
        showCanvas && 
        <Canvas className="lbb-canvas" width={width} height={height} />
      }
      {
        fabsMenu && <TopRightFab/>  
      }
      
        {/* <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Player page</IonTitle>
          </IonToolbar>
        </IonHeader> */}
        
        <GridContainer />
      </IonContent>
    </IonPage>
    </FriendsClientProvider>
    </WebrtcClientProvider>
    </RoomClientProvider>  
  );
};

export default withRouter(PlayerPage);