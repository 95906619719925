import React, { useState, useEffect } from 'react'
import ReactRotatingText from 'react-rotating-text';

const gifs = [
    "https://media.giphy.com/media/l0MYt5jPR6QX5pnqM/giphy.gif",
    "https://media.giphy.com/media/JebM0Qb1bAouI/giphy.gif",
    // "https://media.giphy.com/media/fM3xoVGOpR90Q/giphy.gif",
    "https://media.giphy.com/media/XWKrUYz1N5J7i/giphy.gif",
    
    "https://media.giphy.com/media/WsKVAem02Efuw/giphy.gif",
    "https://media.giphy.com/media/4a9SouoxGMzZ6yUSjy/giphy.gif",
    "https://media.giphy.com/media/3o7qDQ4kcSD1PLM3BK/giphy.gif",
    "https://media.giphy.com/media/bCMp3MbSizF9C/giphy.gif",
    "https://media.giphy.com/media/l2R0aBfiHgMyWxn9u/giphy.gif",
    "https://media.giphy.com/media/hr9kzCIYjzGY0nhT2V/giphy.gif",
    "https://media.giphy.com/media/l41Yh18f5TbiWHE0o/giphy.gif",
    "https://media.giphy.com/media/iqsZS1g8eiOS06FJZw/giphy.gif",
    "https://media.giphy.com/media/IbCUIr9bAqPlXmGlOj/giphy.gif",
    "https://media.giphy.com/media/l3q2NjH5CualVMebK/giphy.gif",
    "https://media.giphy.com/media/jUL0wu4RqyWokuKTFD/giphy.gif",
    "https://media.giphy.com/media/RH7fnjpaqCsNGQY9my/giphy.gif",
    "https://media.giphy.com/media/2aAcLrYtiX8YM/giphy.gif",
    "https://media.giphy.com/media/l0IyejpP3UygbYry8/giphy.gif",
    "https://media.giphy.com/media/xThtax9MjDVVR2DoeQ/giphy.gif",
    "https://media.giphy.com/media/DQ9bJUeaPd4AM/giphy.gif",
    "https://media.giphy.com/media/2fOzxX43ffN2E/giphy.gif",
    "https://i.makeagif.com/media/3-06-2015/mAd2hK.gif",
    
    "https://media.giphy.com/media/NlD11eNlfPhIs/giphy.gif"

]

const PlaceholderGifs = () => {
    const [counter, setCounter] = useState<number>(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCounter((prev) => prev < (gifs.length - 1) ? prev + 1 : 0)
        }, 5000);
        return () => clearInterval(interval);
    }, [])
    return (
        <div className="video-placeholder">
            <img src={gifs[counter]} alt="" />
            <div className="placeholder-text">
                <ReactRotatingText items={['GETTING READY...', 'Put on your dancing shoes', 'Loading some very exciting content here...', 'Burn up the dance floor']} />
            </div>
        </div>
    )
}

export default PlaceholderGifs;