import React, { useState } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy'; import { IonItem, IonContent, IonBadge, IonCol, IonGrid, IonHeader, IonRouterLink, IonRow, IonTitle, IonToolbar, IonButton, IonIcon, IonButtons, IonMenuButton, IonLabel } from '@ionic/react';
import { personOutline, logOutOutline, ellipsisHorizontal, ellipsisVertical, people } from 'ionicons/icons';
import ProfileModal from './ProfileModal';
import UserModal from './UserModal';
import IsOnlineBadge from './IsOnlineBadge';
import './Header.css';
import SettingsDashboard from './SettingsDashboard';


interface HeaderProps { }

const Header: React.FC<HeaderProps> = () => {
  const signOut = useStoreActions((s: any) => s.auth.signOut);
  const authenticated = useStoreState((s: any) => s.auth.authenticated);


  const user = useStoreState((s: any) => s.auth.user);
  //const totalCount = useStoreState((s: any) => s.friends.totalCount);

  const showUserProfile = useStoreActions((s: any) => s.userModal.showUserProfile);
  const showProfile = useStoreActions((s: any) => s.profileModal.showProfile);

  const handleSignOut = (e: any) => {
    signOut();
  };

  return (
    <IonHeader id="main-header" className="main-header">
      <IonToolbar className="lbb-top-toolbar">
        {/* <IonGrid>
          <IonRow>
            <IonCol>
              <IonTitle>LaBoum Builder</IonTitle>
            </IonCol>
            <IonCol>
              <IonRouterLink color="dark" href="/home"><IonButton>Home</IonButton></IonRouterLink>
            </IonCol>
            <IonCol>
              <IonRouterLink color="dark" href="/builder"><IonButton>Builder</IonButton></IonRouterLink>
            </IonCol>
            <IonCol>
              <IonRouterLink color="dark" href="/910rocket"><IonButton>910Rocket</IonButton></IonRouterLink>
            </IonCol>
            <IonCol>
            <IonButton onClick={()=>{showUserProfile(user.uid)}} >
              <IonIcon icon={personOutline} slot="icon-only"  />
            </IonButton>
            </IonCol>

            <IonCol>
            </IonCol>

          </IonRow>
        </IonGrid> */}


        {/* <ProfileModal /> */}
        <SettingsDashboard />
        <UserModal />

        <IonButtons className="lbb-header-buttons ion-justify-content-start" slot="start">
          {/* <IonMenuButton className="lbb-header-button" auto-hide="false">
            <IonIcon className="lbb-header-icon" icon={people} />
          </IonMenuButton> */}
          {/* <IsOnlineBadge size="large" header={true} />
          <IonBadge color="light" style={{ marginLeft: 35 }}>LIVE</IonBadge> */}
        </IonButtons>

        {/*<IsOnlineBadge size="large" header={true} noRipple={true} />*/}

        {
          authenticated ? (
            <>
              <IonButtons className="lbb-header-buttons" slot="start">
                <IonButton className="lbb-header-button ion-margin-horizontal opacityzero" onClick={() => { showProfile() }} >
                  <IonIcon icon={personOutline} slot="icon-only"></IonIcon>
                </IonButton>
              </IonButtons>

              {/*                    
                        (totalCount > 0 ?
                        (<IonBadge color="danger" style={{ position: "absolute", right: "16px", top: "1px"}}>{totalCount}</IonBadge>)
                        : null)
                    }  */}

              <IonButtons className="lbb-header-buttons" slot="end">
                <IonButton className="lbb-header-button ion-margin-horizontal" onClick={e => handleSignOut(e)}>
                  <IonIcon slot="icon-only" icon={logOutOutline} />
                </IonButton>
              </IonButtons>
            </>
          ) : null
        }

        <IonTitle className="ion-text-center">BlingB Party</IonTitle>
      </IonToolbar>
    </IonHeader>
  );
};

export default Header;