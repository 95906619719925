import React, { useState, useEffect } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { IonThumbnail, IonImg, IonCard, IonCardHeader, IonButtons, IonCardSubtitle, IonCardTitle, IonCardContent, IonAvatar, IonButton, IonChip, IonCol, IonContent, IonFooter, IonGrid, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonModal, IonPickerColumn, IonRow, IonTitle, IonToolbar, IonSpinner, IonSkeletonText } from '@ionic/react';
import { closeOutline, peopleOutline } from 'ionicons/icons';

import Avatar from '../Common/Avatar';

import './UserModal.css';

interface UserModalProps {
}

const UserModal: React.FC<UserModalProps> = (props) => {
  const loading = useStoreState((s: any) => s.userModal.loading);
  const setIsOpen = useStoreActions((s: any) => s.userModal.setIsOpen);
  const isOpen = useStoreState((s: any) => s.userModal.isOpen);
  const userProfile = useStoreState((s: any) => s.userModal.userProfile);
  const age = useStoreState((s: any) => s.userModal.age);
  const uid = useStoreState((s: any) => s.userModal.uid);
  const loggedInUser = useStoreState((s: any) => s.auth.user);

  const [isFriendRequestSent, setIsFriendRequestSent] = useState(false);

  useEffect(() => {
    if (userProfile) {
      setIsFriendRequestSent(userProfile.isFriendRequestSent);
    }
  }, [userProfile]);

  const createFriendRequest = useStoreActions((s: any) => s.friends.createFriendRequest);
  const deleteFriendRequest = useStoreActions((s: any) => s.friends.deleteFriendRequest);
  const acceptFriendRequest = useStoreActions((s: any) => s.friends.acceptFriendRequest);

  const handleCreateFriendRequest = () => {
    createFriendRequest(uid);
    setIsFriendRequestSent(true);
  };

  const handleDeleteFriendRequest = () => {
    deleteFriendRequest(uid);
    setIsFriendRequestSent(false);
  };

  const handleAcceptFriendRequest = () => {
    acceptFriendRequest(uid);
    //setIsFriendRequestSent(false);
  };


  const renderFriendButtons = () => {

    if (uid == loggedInUser.uid) {
      return null;
    }

    if (!userProfile.isFriend) {
      if (userProfile.isFriendRequestReceived) {
        return (
          <IonButtons>
            <IonButton onClick={() => handleAcceptFriendRequest()}>
              <IonIcon icon={peopleOutline} /> Accept friend request
            </IonButton>
          </IonButtons>
        );
      } else {
        if (isFriendRequestSent) {
          return (
            <IonButtons>
              <IonButton onClick={() => handleDeleteFriendRequest()}>
                <IonIcon icon={peopleOutline} /> Remove friend request
              </IonButton>
            </IonButtons>
          );
        } else {
          return (
            <IonButtons>
              <IonButton onClick={() => handleCreateFriendRequest()}>
                <IonIcon icon={peopleOutline} /> Send friend request
              </IonButton>
            </IonButtons>
          );
        }
      }
    } else {
      return (<p className="friends-with">You are friends with {userProfile.displayName}.</p>);
    }
  }

  const showPrivateToFriends = () => {
    if (!userProfile.isFriend) {
      return null;
    }

    return (
      <div>
        { userProfile.facebook ? (<span>Facebook: <a href={'https://facebook.com/'+userProfile.facebook} target="_blank">{userProfile.facebook}</a> </span>) : null}<br />
        { userProfile.instagram ? (<span>Instagram: <a href={'https://instagram.com/'+userProfile.instagram} target="_blank">{userProfile.instagram}</a> </span>) : null}
      </div>
    );

  };

  if (!isOpen || !userProfile) {
    return null;
  }

  return (
    <div id="profile-modal" className="">
      <IonModal isOpen={isOpen} onDidDismiss={() => setIsOpen(false)} cssClass='my-custom-class'>
        <IonHeader>
          <IonToolbar>
            <IonTitle>Profile</IonTitle>
            <IonButtons slot="end" onClick={() => { setIsOpen(false); }}>
              <IonButton>
                <IonIcon icon={closeOutline} slot="icon-only" />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonCard>
            <IonCardHeader>
              {
                !loading ?
                  (
                    <IonGrid>
                          <IonRow>
                            <IonCol size="2">
                              <Avatar uid={uid} />
                            </IonCol>
                            <IonCol size="10">
                              <IonCardTitle>{userProfile.displayName}</IonCardTitle>
                              <IonCardSubtitle>{userProfile.location}{age ? ', ' + age : ''}</IonCardSubtitle>
                              {showPrivateToFriends()}
                              <br />
                              {userProfile.bio}

                            </IonCol>
                          </IonRow>
                    </IonGrid>
                    )
                  : (<><IonSkeletonText animated style={{ width: '88%' }} />
                    <IonSkeletonText animated style={{ width: '60%' }} /></>)
              }

            </IonCardHeader>
            <IonCardContent>
              {
                !loading ?
                  (
                    <>
                      <div>
                        <IonGrid>
                          <IonRow>
                            <IonCol>
                              {userProfile.displayName}'s Instagram images:
                            </IonCol>
                          </IonRow>
                          <IonRow>
                            <IonCol className="insta-wrapper">


                              {
                                (userProfile.instagram_media && userProfile.instagram_media.medias ?
                                  userProfile.instagram_media.medias.map((m: any) => {
                                    return (<IonThumbnail className="insta-images" style={{ "--size": "60px", "float": "left" }}  ><IonImg src={m.media_url} /></IonThumbnail>);
                                  })
                                  : null)
                              }
                            </IonCol>
                          </IonRow>
                        </IonGrid>
                      </div>
                    </>
                  )
                  : (<><IonSkeletonText animated style={{ width: '88%' }} />
                  <IonSkeletonText animated style={{ width: '88%' }} />
                  <IonSkeletonText animated style={{ width: '88%' }} />
                  <IonSkeletonText animated style={{ width: '75%' }} />
                  <IonSkeletonText animated style={{ width: '75%' }} />
                  <IonSkeletonText animated style={{ width: '88%' }} /></>)
                  
              }

            </IonCardContent>
          </IonCard>
        </IonContent>
        <IonFooter>
          <IonGrid>
            <IonRow>
              <IonCol>
                {
                  (!loading) ?
                    (
                      renderFriendButtons()
                    )
                    : <IonSpinner name="dots" />
                }
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonFooter>

      </IonModal>
    </div>
  );
};

export default UserModal;