import authModel from './authModel';
import roomModel from './roomModel';
import friendsModel from './friendsModel';
import profileModalModel from './profileModalModel';
import userModalModel from './userModalModel';
import joinModalModel from './joinModalModel';

import roomClientModel from './roomClientModel';
import webrtcClientModel from './webrtcClientModel';
import toastModel from './toastModel';

const model = {
  auth: authModel,
  room: roomModel,
  roomClient: roomClientModel,
  webrtcClient: webrtcClientModel,
  friends: friendsModel,
  profileModal: profileModalModel,
  userModal: userModalModel,
  joinModal: joinModalModel,
  toast: toastModel
};

export default model;