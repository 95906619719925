import { IonBadge } from '@ionic/react';
import React, { useEffect, useState, useCallback } from 'react';
import { useStoreState } from 'easy-peasy';

import ReactPlayer from 'react-player'

import './DisplayView.css';

interface DisplayViewProps {
  state: any;
}

const DisplayView: React.FC<DisplayViewProps> = (props: any) => {

  const [playing, setPlaying] = useState(false);
  const [url, setUrl] = useState("");

  useEffect(() => {
    //if (isJoined) {
    if (!url) {
      setUrl(props.state.url);
    }
    setPlaying(true);
    //}
  }, [props.state.url]);


  /*
  useEffect(() => {
    if (isJoined && !playing) {
      //console.log("isJoined", isJoined, 'playing', playing, "psu", props.state.url);
      setUrl(props.state.url);
      //setPlaying(true);
    }
  }, [isJoined]);
  
  */

  const handleOnClick = () => {
    var o = (window as any).VIDEO_PLAYER;
    if (o && o.player && o.player.player && o.player.player.player) {
      o.player.player.player.play();
    }
  }

  const handleOnError = (err: any, data: any, hlsInstance: any, hlsGlobal: any) => {
    if (!data || data.type === 'networkError') {
      if (data && (data.type === 'networkError')) {
        setUrl("");
      }

      setTimeout(() => {
        setUrl(props.state.url);
        setPlaying(true);
      }, 10000);
    }
  }



  const [ref, setRef] = useState(null);

  const onRefChange = useCallback(node => {
    setRef(node);
    if (node === null) {
    } else {
      (window as any).VIDEO_PLAYER = node;
    }
  }, []);


  return (
    <div className="display-container">
      {
        props.state && props.state.url ?
          <div>
            <ReactPlayer
              ref={onRefChange}
              playsinline={true}
              muted={false}
              className="hls-video-wrapper"
              url={url}
              onError={handleOnError}
              width="100%"
              height="100%"
              playing={playing}
              onClick={handleOnClick}
              config={
                {
                  file: {
                    hlsOptions: {
                      autoStartLoad: true,
                      liveSyncDuration: 1, liveMaxLatencyDuration: 4
                    }
                  }
                }
              } />
          </div>
          : <strong>Currently there is no live streaming.</strong>
      }
      <IonBadge className="live-badge" color="light">LIVE</IonBadge>
    </div>
  );
};

export default DisplayView;