import React from 'react';
import { IonCol, IonGrid, IonRow, IonText } from '@ionic/react';


const Admin: React.FC = () => {
    return (
        <IonGrid>
            <IonRow className="board-title">
            <IonCol>
                  <IonText>
                    <h2>ADMIN</h2>
                  </IonText>  
                </IonCol>
            </IonRow>
        </IonGrid>
    )
}

export default Admin;


