import React, { useState, useEffect } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { IonTextarea, IonButtons, IonAvatar, IonButton, IonChip, IonCol, IonContent, IonFooter, IonGrid, IonHeader, IonIcon, IonImg, IonInput, IonItem, IonLabel, IonModal, IonPickerColumn, IonRow, IonThumbnail, IonTitle, IonToolbar, IonText } from '@ionic/react';
import { personOutline, closeOutline, logoInstagram } from 'ionicons/icons';
import Webcam from "react-webcam";

import ReactPlayer from 'react-player'

import './JoinModal.css';

interface JoinModalProps {
}

const JoinModal: React.FC<JoinModalProps> = (props) => {

  const user = useStoreState((s: any) => s.auth.user);

  //const setIsOpen = useStoreActions((s: any) => s.joinModal.setIsOpen);
  const isOpen = useStoreState((s: any) => !s.room.isJoined);
  const roomClient = useStoreState((s: any) => s.room.roomClient);
  const isDisplayNameUpdated = useStoreState((s: any) => s.joinModal.isDisplayNameUpdated);

  const saveJoinData = useStoreActions((s: any) => s.joinModal.saveJoinData);
  const joinData = useStoreState((s: any) => s.joinModal.joinData);

  // const [changeNameOpen, setChangeNameOpen] = useState<boolean>(false);
  const [newDisplayName, setNewDisplayName] = useState<string>('');
  const inputRef = React.useRef<any>(null);

  const waitingRoomRank = useStoreState((s: any) => s.room.waitingRoom.rank);


  // useEffect(() => {
  //   if (changeNameOpen) {
  //     setTimeout(() => inputRef.current.setFocus(), 100);
  //   }
  // }, [changeNameOpen]);

  useEffect(() => {
    if (joinData && joinData.isSet) {
      roomClient.join(joinData);
    }
  }, [joinData]);

  const handleJoin = (joinData = {}) => {
    var o = (window as any).VIDEO_PLAYER;
    if (o && o.player && o.player.player && o.player.player.player) {
      o.player.player.player.play();
    }

    if (newDisplayName) {
      saveJoinData({ displayName: newDisplayName, ...joinData, isSet: true });
    } else {
      roomClient.join(joinData);
    }
  };

  if (!isOpen || !user) {
    return null;
  }

  const nameChangerField = () => {

    return (
      <div className="change-display-name-wrapper">
        <IonInput
          className="change-display-name"
          type="text"
          placeholder="Change display name here then click join."
          value={newDisplayName}
          onInput={(e: any) => setNewDisplayName(e.target.value)}
          ref={(ref) => inputRef.current = ref}
        />
      </div>
    );
  }

  const joinButtons = () => {
    if (waitingRoomRank !== null) {
      return (<div>The party is full.<br /> You are in the line. Number of people before you: { waitingRoomRank == 0 ? 'no one! you are the next to enter!' : waitingRoomRank}</div>);
    } else {
      if (user.isAdmin) {
        return (
          <div>
            <IonButton expand="block" onClick={() => handleJoin({ isObserver: true })}>Join as observer with name {newDisplayName ? newDisplayName : user.displayName}</IonButton>
            <IonButton expand="block" onClick={() => handleJoin()}>Join party as {newDisplayName ? newDisplayName : user.displayName}</IonButton>
            {nameChangerField()}
          </div>
        );
      } else {
        return (
          <div className="join-button-wrapper">
            { nameChangerField()}
            {/* <IonText className="ion-text-center"><p>or</p></IonText> */}
            <IonButton expand="block" fill="outline" className="join-button join-button-solid" onClick={() => handleJoin()}>JOIN PARTY as<span className="display-name">{newDisplayName ? newDisplayName : user.displayName}</span></IonButton>

          </div>
        );
      }
    }
  }

  return (
    <IonModal isOpen={isOpen} cssClass='profile-modal'>
      <IonContent>
        <IonGrid>
          <IonRow>
            <IonCol size="6">
              <Webcam className="ion-margin-top ion-margin-bottom" style={{ width: '100%' }} mirrored={true} audio={false} />
            </IonCol>
            <IonCol size="6">
              <div className="contentWrapper">
                <h2 id="transition-modal-title">Check your camera</h2>
                <p id="transition-modal-description" className="ion-no-margin">Please allow access to your camera</p>
                <h3>Having problems?</h3>
                <p className="ion-no-margin">Make sure that all other programs that utilize your camera are closed.</p>
                <p>Check your browser's camera settings.</p>
                <p>Sometimes a simple page refresh will resolve your problems.</p>
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
      <IonFooter>
        <IonGrid>
          <IonRow>
            <IonCol>
              {joinButtons()}


            </IonCol>
          </IonRow>
        </IonGrid>
      </IonFooter>
    </IonModal>
  );
};

export default JoinModal;