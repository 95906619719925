import React from 'react';
import './Video.css';

interface VideoProps {

	state: any;
}

const Video: React.FC<VideoProps> = (props) => {
  return (
    <div className="lbb-video-container">
      <strong>Video module: {props.state.title}</strong>
    </div>
  );
};

export default Video;
