import { action } from 'easy-peasy';

const producersModel = {

  producers: {},

  addProducer: action((state, payload) => {
    const producer = payload;
    state.producers[producer.id] = producer;
  }),

  removeProducer: action((state, payload) => {
    const producerId = payload;
    delete state.producers[producerId];
  }),

  setProducerPaused: action((state, payload) => {
    const { producerId, originator } = payload;
    if (originator === 'local') {
      state.producers[producerId].locallyPaused = true;
    } else {
      state.producers[producerId].remotelyPaused = true;
    }
  }),

  setProducerResumed: action((state, payload) => {
    const { producerId, originator } = payload;
    if (originator === 'local') {
      state.producers[producerId].locallyPaused = false;
    } else {
      state.producers[producerId].remotelyPaused = false;
    }
  }),

  setProducerTrack: action((state, payload) => {
    const { producerId, track } = payload;
    state.producers[producerId].track = track;
  }),

  setProducerScore: action((state, payload) => {
    const { producerId, score } = payload;
    if (!state.producers[producerId]) { return; }
    state.producers[producerId].score = score;
  })

};

export default producersModel;