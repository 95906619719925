import React, { useState, useEffect } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';

import './Friends.css';
import { IonAvatar, IonBadge, IonButton, IonCol, IonGrid, IonIcon, IonInput, IonItem, IonLabel, IonList, IonListHeader, IonPickerColumn, IonRow, IonSegment, IonSegmentButton, IonText, IonToolbar } from '@ionic/react';
import { thumbsUp, thumbsDown } from 'ionicons/icons';
import IsOnlineBadge from '../../IsOnlineBadge';
import Avatar from '../../Common/Avatar';

//interface FriendsProps { }

//const Friends: React.FC<FriendsProps> = () => {
const Friends = ({ header = true, selectedArray = "" }) => {

	const friendRequests = useStoreState(state => state.friends.friendRequests);
	const friends = useStoreState(state => state.friends.friends);
	const friendsClient = useStoreState(state => state.friends.friendsClient);
	const storeActions = useStoreActions(state => state.friends);
	const showUserProfile = useStoreActions(s => s.userModal.showUserProfile);

    const friendRequestCount = useStoreState(s => s.friends.friendRequestCount);
    const friendsCount = useStoreState(s => s.friends.friendsCount);


	const [selectedSegment, setSelectedSegment] = useState(selectedArray);

	const [dummyFriends, setDummyFriends] = useState([]);
	const [dummyFriendRequests, setDummyFriendRequests] = useState([]);
	const fetchDummyData = () => {
		fetch("https://randomuser.me/api/?results=15")
			.then(response => response.json())
			.then(data => {
				const array1 = data.results.map((result) => ({
					...result,
					displayName: `${result.name.first} ${result.name.last}`,
					location: result.location.city,
					avatar: result.picture.thumbnail
				}))
				setDummyFriends(array1)

				const array2 = array1.filter((item) => {
					if (item.gender === "female") {
						return item
					}
				})
				setDummyFriendRequests(array2)
			})
	}

	React.useEffect(() => {
		storeActions.fetchFriends();
		//fetchDummyData();
	}, [friendsClient])

	React.useEffect(() => {
		storeActions.fetchFriends();
	}, [friendRequestCount, friendsCount])	

	React.useEffect(()=>{
		setSelectedSegment(selectedArray)
	}, [selectedArray])

	const changeSegmentHandler = (e) => {
		setSelectedSegment(e.detail.value);
	}

	const handleCreateFriendRequest = (e) => {
		storeActions.createFriendRequest({});
	};

	const handleAcceptRequest = (fr) => {
		storeActions.acceptFriendRequest(fr);
	};

	const handleRejectRequest = (fr) => {
		storeActions.rejectFriendRequest(fr);
	};

	return (
		<div id="friends" className="lbb-friends-container">
			{
				header &&
				<IonToolbar>
					<IonSegment value={selectedSegment} onIonChange={changeSegmentHandler}>
						<IonSegmentButton className="friends-segment-button" value="friends">
							<IonLabel>Friends</IonLabel>
						</IonSegmentButton>
						<IonSegmentButton className="friends-segment-button" value="requests">
							<IonLabel>Requests</IonLabel>
						</IonSegmentButton>
					</IonSegment>
				</IonToolbar>
			}

			<div className="lbb-friends-content-wrapper">

				{
					selectedSegment === 'requests' &&
					<React.Fragment>
						{/*<IonListHeader><IonLabel>Pending requests</IonLabel></IonListHeader>*/}
						<IonList>
							{
								// friendRequests
								friendRequests.map((fr, i) => {
									return (
										<IonItem key={i}>
											<Avatar uid={fr.uid} className="friends-avatar" slot="start"/>
											<IonLabel>
												<h2>{fr.displayName}</h2>
												<h3>{fr.location}</h3>
											</IonLabel>
											<IonButton onClick={(e) => handleAcceptRequest(fr.uid)} >
												<IonIcon slot="icon-only" color="success" icon={thumbsUp} title="Accept" />
											</IonButton>
											<IonButton onClick={(e) => handleRejectRequest(fr.uid)}>
												<IonIcon slot="icon-only" color="danger" icon={thumbsDown} title="Reject" />
											</IonButton>
										</IonItem>
									);
								})
							}
						</IonList>
					</React.Fragment>

				}

				{
					selectedSegment === 'friends' &&
					<React.Fragment>
						{/*<IonListHeader><IonLabel>Friends</IonLabel></IonListHeader>*/}
						<IonList>
							{	// friends
								friends.map((friend, i) => {
									return (
										<IonItem key={i}>
											<Avatar uid={friend.uid} className="friends-avatar" slot="start" />
											<IonLabel>
												<h2>{friend.displayName}</h2>
												<h3>{friend.location}</h3>
											</IonLabel>
										</IonItem>);
								})
							}
						</IonList>
					</React.Fragment>
				}
			</div>
		</div>
	);
};

export default Friends;
