import { action, thunk } from 'easy-peasy';

const consumersModel = {

  consumers: {},



  addConsumer: thunk(async (actions, payload, { getStoreActions }) => {
    actions._addConsumer(payload);
    getStoreActions().webrtcClient.addPeerConsumer({uid: payload.uid, consumer: payload });
  }),

  _addConsumer: action((state, payload) => {
    const consumer = payload;
    state.consumers[consumer.id] = consumer;
  }),

  removeConsumer: action((state, payload) => {
    const consumerId = payload;
    delete state.consumers[consumerId];
  }),

  setConsumerPaused: action((state, payload) => {
    const { consumerId, originator } = payload;
    if (originator === 'local') {
      state.consumers[consumerId].locallyPaused = true;
    } else {
      state.consumers[consumerId].remotelyPaused = true;
    }
  }),

  setConsumerResumed: action((state, payload) => {
    const { consumerId, originator } = payload;
    if (originator === 'local') {
      state.consumers[consumerId].locallyPaused = false;
    } else {
      state.consumers[consumerId].remotelyPaused = false;
    }
  }),

  setConsumerCurrentLayers: action((state, payload) => {
    const { consumerId, spatialLayer, temporalLayer } = payload;
    state.consumers[consumerId].currentSpatialLayer = spatialLayer;
    state.consumers[consumerId].currentTemporalLayer = temporalLayer;
  }),

  setConsumerPreferredLayers: action((state, payload) => {
    const { consumerId, spatialLayer, temporalLayer } = payload;
    state.consumers[consumerId].preferredSpatialLayer = spatialLayer;
    state.consumers[consumerId].preferredTemporalLayer = temporalLayer;
  }),

  setConsumerPriority: action((state, payload) => {
    const { consumerId, priority } = payload;
    state.consumers[consumerId].priority = priority;
  }),

  setConsumerTrack: action((state, payload) => {
    const { consumerId, track } = payload;
    state.consumers[consumerId].track = track;
  }),

  setConsumerScore: action((state, payload) => {
    const { consumerId, score } = payload;
    if (!state.consumers[consumerId]) { return; }
    state.consumers[consumerId].score = score;
  })

};

export default consumersModel;