import React from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';

import { IonAvatar, IonCol, IonGrid, IonRow, IonText } from '@ionic/react';
import IsOnlineBadge from '../../IsOnlineBadge';
import md5 from 'md5';

import './Avatar.css'

interface AvatarProps {
    uid: string;
    className?: string;
    slot?: string;
}

const Avatar: React.FC<AvatarProps> = ({slot= "", ...props}) => {

    const showUserProfile = useStoreActions((s: any) => s.userModal.showUserProfile);
    const roomState = useStoreState((s: any) => s.room.state);

    const isOnline = roomState && roomState.players && roomState.players[props.uid] && roomState.players[props.uid].isOnline;

    return (
        <IonAvatar slot={slot} className="lbb-avatar" onClick={() => showUserProfile(props.uid)}>
            <img src={'https://storage.googleapis.com/laboum-live.appspot.com/avatars/'+props.uid+'.jpg'} />
            {isOnline && <IsOnlineBadge />}
        </IonAvatar>
    )
}

export default Avatar;