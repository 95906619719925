import React, { useState, useEffect } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';

import RoomClient from './RoomClient'

export const RoomContext = React.createContext(undefined);

const RoomClientProvider = (props) => {

	const storeActions = useStoreActions(x => x.room);
	const roomClient = useStoreState(x => x.room.roomClient);

	useEffect(() => {
		if (!props.user) {
			return;
		}
		if (!props.roomId) {
			return;
		}

		if (roomClient) {
			if ((roomClient._peerId == props.user.uid) && (roomClient._roomId == props.roomId)) {
				return;
			}
		}
		
		// eslint-disable-next-line
		const initRoomClient = (appId, roomId, user) => {
		    return new RoomClient(
		      {
		      	storeActions,
		      	appId,
		        roomId,
		        peerId: user.uid,
		        peerDisplayName: user.displayName
		      });
		}

		const rc = initRoomClient(props.appId, props.roomId, props.user);
		storeActions.setRoomClient(rc);
	}, [props.roomId, storeActions, props.user]);

	useEffect(() => {
		if (roomClient) {
			roomClient.setDisplayName(props.user.displayName);
		}
	}, [props.user]);

    return (
	    <RoomContext.Provider value={roomClient}>
        	{props.children}	    
        </RoomContext.Provider>
    )

};

export default RoomClientProvider
