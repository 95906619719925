import { action } from 'easy-peasy';

import deviceInfo from '../../components/WebrtcClient/deviceInfo'


const meModel = {

  id                   : '',
  device               : deviceInfo(),
  canSendMic           : false,
  canSendWebcam        : false,
  canChangeWebcam      : false,
  webcamInProgress     : false,
  shareInProgress      : false,
  audioOnly            : false,
  audioOnlyInProgress  : false,
  audioMuted           : false,
  restartIceInProgress : false,
  // TODO reinit when close.

  setAudioMutedState: action((state, payload) => {
    const audioMuted = payload;
    state.audioMuted = audioMuted;
  }),

  setAudioOnlyState: action((state, payload) => {
    const audioOnly = payload;
    state.audioOnly = audioOnly;
  }),

  setAudioOnlyInProgress: action((state, payload) => {
    const audioOnlyInProgress = payload;
    state.audioOnlyInProgress = audioOnlyInProgress;
  }),

  setCanChangeWebcam: action((state, payload) => {
    state.canChangeWebcam = payload;
  }),

  setMediaCapabilities: action((state, payload) => {
    const { canSendMic, canSendWebcam } = payload;
    state.canSendMic = canSendMic;
    state.canSendWebcam = canSendWebcam;
  }),

  setRestartIceInProgress: action((state, payload) => {
    const restartIceInProgress = payload;
    state.restartIceInProgress = restartIceInProgress;
  }),
  
  setShareInProgress: action((state, payload) => {
    state.shareInProgress = payload;
  }),

  setWebcamInProgress: action((state, payload) => {
    state.webcamInProgress = payload;
  }),

  

};

export default meModel;