import { action, thunk } from 'easy-peasy';
import axios from 'axios';
import { getProfileApiUrl } from '../urlFactory'
import ToastContainer from '../components/ToastContainer';

const profileModalModel = {

    profile: {},
    isOpen: false,
    loading: true,

    showProfile: thunk(async (actions, uid) => {
      actions.setLoading(true);
      actions.setIsOpen(true);
      await axios.get(getProfileApiUrl('/fetch'))
        .then(res => {
          actions.setProfile(res.data.profile);
          actions.setLoading(false);
        })
        .catch(err => err);
    }),

    setIsOpen: action((state, isOpen) => {
      state.isOpen = isOpen;
    }),

    setLoading: action((state, loading) => {
      state.loading = loading;
    }),

    setProfile: action((state, profile) => {
      state.profile = profile;
    }),

    saveProfile: thunk(async (actions, profile, { getStoreActions }) => {
      try {
        const { p } = await axios.post(getProfileApiUrl('/save'), { profile });
        getStoreActions().toast.makeToast("Successfully saved.");
        //actions.setProfile(p);
      } catch (e) {
        console.log(e);//TODO
      }
    }),

    saveAvatar: thunk(async (actions, { uid, image }, { getStoreActions }) => {
      try {
        const { p } = await axios.post(getProfileApiUrl('/avatar'), { image });
        getStoreActions().toast.makeToast("Profile pic saved.");
        //actions.setProfile(p);
      } catch (e) {
        console.log(e);//TODO
      }
    }),

};

export default profileModalModel;