import React from 'react';
import { IonFab, IonFabButton, IonFabList, IonIcon, IonLabel } from '@ionic/react';
import { add, logoFacebook, logoTwitter, logoYoutube, menu } from 'ionicons/icons'


const TopRightFab: React.FC = (props) => {
    return (

        <IonFab className="lbb-top-right-fab" vertical="top" horizontal="end" slot="fixed">
            <IonFabButton className="lbb-fab-main-button" >
                <IonIcon className="lbb-fab-icon" src="../assets/solar_panel.svg" />
            </IonFabButton>
            <IonFabList side="bottom">
                <IonFabButton  className="lbb-fab-button">
                    <IonIcon className="lbb-fab-icon" src="../assets/boombox.svg" />
                </IonFabButton>
                <IonFabButton className="lbb-fab-button">
                    <IonIcon className="lbb-fab-icon" src="../assets/gift.svg"  />
                </IonFabButton>
                <IonFabButton className="lbb-fab-button">
                    <IonIcon className="lbb-fab-icon" src="../assets/gearwheel.svg"/>
                </IonFabButton>
                <IonFabButton className="lbb-fab-button">
                    <IonIcon className="lbb-fab-icon" src="../assets/door_exit.svg"/>
                </IonFabButton>
            </IonFabList>
        </IonFab>
    )
}

export default TopRightFab;