import React, { useContext, useState, useEffect } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';

import './GridContainer.css';
import _uniqueId from 'lodash/uniqueId';

import useWindowDimensions from './utilities/useWindowDimensions';
// import Canvas from './canvasBackground';

import {
  useIonViewWillEnter,
} from '@ionic/react';


import { catalogue as laBoumLibrary, moduleFactory } from './laBoumLibrary'
import { RoomContext } from '../components/RoomClient/RoomClientProvider';

import { Responsive, WidthProvider, Layout as GridLayout, Layout } from 'react-grid-layout';
import { useGlobalStyles } from './customHooks/useGlobalStyles';
/*
const mdView = [
  { i: "lbb-1", x: 0, y: 1, w: 1, h: 3 },
  { i: "lbb-3", x: 0, y: 0, w: 1, h: 1 },
  { i: "lbb-2", x: 0, y: 4, w: 1, h: 3 },
  { i: "lbb-4", x: 0, y: 7, w: 1, h: 1 },
  { i: "lbb-5", x: 0, y: 8, w: 1, h: 1 },
  { i: "lbb-8", x: 0, y: 9, w: 1, h: 4 },
  { i: "lbb-6", x: 0, y: 13, w: 1, h: 5 },
  { i: "lbb-7", x: 0, y: 18, w: 1, h: 5 },
]
*/

const ResponsiveReactGridLayout = WidthProvider(Responsive);

interface ContainerProps {
}

const GridContainer: React.FC<ContainerProps> = () => {
  const [showGrid, setShowGrid] = useState<boolean>(false)
  const { height, width } = useWindowDimensions();
  const setLayout = useStoreActions((store: any) => store.room.setLayout);
  const layout = useStoreState((store: any) => store.room.layout);
  const roomClient = useContext<any>(RoomContext);
  const isJoined = useStoreState((store: any) => store.room.isJoined);

  useGlobalStyles(false, "#36454f", "#929292"); 

  // useEffect(() => {

  //   const savedLayout = localStorage.getItem("gridLayout")
  //   if (savedLayout === null) {
  //     return
  //   }
  //   const parsedLayout = JSON.parse(savedLayout)
  //   //console.log(parsedLayout)
  //   setLayout(parsedLayout)
  // }, [])


  useIonViewWillEnter(() => {
    setShowGrid(true);
  });

  const onLayoutChange = (layout: Layout[]) => {
    // console.log(layout)
    // console.log(JSON.stringify(layout))
  }

  return (
    <div className="grid-container rocket-background">
      {
        isJoined && showGrid &&
        <ResponsiveReactGridLayout
          className="layout"
          breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
          cols={{ lg: 12, md: 1, sm: 1, xs: 1, xxs: 1 }}
          measureBeforeMount={true}
          onLayoutChange={onLayoutChange}
          layouts={layout}
          draggableCancel=".chat-text-area"
          useCSSTransforms={false}
          isResizable={false}
          isDraggable={false}
          rowHeight={(height > 700) ? height / 10 : 75}
        >
          {
            layout.lg && layout.lg.map((item: any, index: number) => {
              return (<div key={item.i}>{moduleFactory(item)}</div>);
            })
          }
        </ResponsiveReactGridLayout>
      }
    </div>
  );
};

export default GridContainer;