import { action, thunk, computed } from 'easy-peasy';

const toastModel = {

    isOpen: false,
    text: null,

    setIsOpen: action((state, isOpen) => {
      state.isOpen = isOpen;
    }),

    setText: action((state, text) => {
      state.text = text;
    }),

    makeToast: thunk(async (actions, text) => {
      actions.setText(text);
      actions.setIsOpen(true);
    })
};

export default toastModel;