import io from 'socket.io-client';
import { auth } from '../../firebase';

//import Logger from './Logger';
//import { getRoomClientUrl } from './urlFactory';
//const logger = new Logger('WebrtcClient');

import { getFriendsApiUrl } from '../../urlFactory';


export default class FriendsClient
{

	constructor(
		{
			storeActions,
			roomId,
			peerId,
			peerDisplayName
		}
	)
	{
		this._storeActions = storeActions;

		this._closed = false;

		this._roomId = roomId;
		this._peerId = peerId;
		this._peerDisplayName = peerDisplayName;

		this._socket = null;
		this.connect();
	}

	close()
	{
		if (this._closed)
			return;

		this._closed = true;

		//logger.debug('close()');

		// Close protoo Peer
		this._socket.close();

		this._storeActions.setConnectionState('closed');
	}

    async _getAccessToken() {
    	return await auth.currentUser.getIdToken();
    }

	async connect()
	{
		if (!this._socket) {
			
			const token = await this._getAccessToken();

			const url = getFriendsApiUrl({ token });
            this._socket = io(url,
            	{
            		path: '/socket.io/',
  				    query: { token },
  				    extraHeaders: {
                        "Authorization": token
                    },
                    secure: true,
            		withCredentials: true,
  				    transports: ['websocket', 'polling']
            	}
            );

            this._socket.on('reconnect_attempt', async () => {
            	const token = await this._getAccessToken();
                this._socket.io.opts.query = {
                    token
                }
            });

            this._socket.on('connect', async () => {
            	this.fetchFriends();
            });

            this._socket.on("friends", (payload) => {
                this._storeActions.setFriends(payload.friends);
                this._storeActions.setFriendRequests(payload.friendRequests);
            });

            this._socket.on("friendCounts", (payload) => {
                this._storeActions.setFriendCounts(payload);
            });
            
			// TODO close, reconnect, etc.
    	}

		this._storeActions.setConnectionState('connecting');
	}

	async createFriendRequest(payload) {
		this._socket.emit('createFriendRequest', payload);
	}

	async deleteFriendRequest(payload) {
		this._socket.emit('deleteFriendRequest', payload);
	}

	async fetchFriends() {
		this._socket.emit('fetchFriends');
	}

    async deleteFriend(fr) {
        this._socket.emit('deleteFriend', fr);
    }

    async acceptFriendRequest(payload) {
        this._socket.emit('acceptFriendRequest', payload);
    }

    async rejectFriendRequest(payload) {
        this._socket.emit('rejectFriendRequest', payload);
    }


}
