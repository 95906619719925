import { action, thunk } from 'easy-peasy';

const roomClientModel = {

  roomState: {},

  setConnectionState: action((state, payload) => {
    const connectionState = payload;
    state.connectionState = connectionState;
  }),

  setRoomState: thunk(async (actions, state, { getStoreActions }) => {
      getStoreActions().room.setState(state);
  }),

  notify: action((state, payload) => {
  	console.log(JSON.stringify(payload));
  })

};

export default roomClientModel;