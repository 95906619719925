import React, { useState, useEffect } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { IonAvatar, IonButton, IonCol, IonGrid, IonRow, IonText } from '@ionic/react';
import Avatar from '../../Common/Avatar';
import Lottie from 'react-lottie';
import heartLottieData from './heart-animation.json';
import './CurrentDancerInfo.css'


const CurrentDancerInfo: React.FC = () => {
    const [heartAnimationIsStopped, setHeartAnimationIsStopped] = useState(true);
    const [friendButtonText, setFriendButtonText] = useState<string>();
    const localStateOnAir = useStoreState((store: any) => store.room.localState.onAir);
    const friends = useStoreState((store: any) => store.friends.friends);
    const friendRequests = useStoreState((store: any) => store.friends.friendRequests);
    
    const user = useStoreState((s: any) => s.auth.user);
    const createFriendRequest = useStoreActions((s: any) => s.friends.createFriendRequest);

    const handleCreateFriendRequest = () => {
        if (localStateOnAir && heartAnimationIsStopped) {
            createFriendRequest(localStateOnAir.uid);
            setHeartAnimationIsStopped(false);
        }
    };

    useEffect(() => {
        if (localStateOnAir && friends.find((f: any) => f.uid == localStateOnAir.uid)) {
            setHeartAnimationIsStopped(false);
        } else {
            setHeartAnimationIsStopped(true);
        }
    }, [localStateOnAir, friends]);

    useEffect(() => {
        if (localStateOnAir) {
            const buttonText: string = getFriendStatus();
            setFriendButtonText(buttonText);
        }
    }, [localStateOnAir, friends, friendRequests])

    const lottieOptions = {
        loop: false,
        autoplay: false,
        animationData: heartLottieData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    }

    const getFriendStatus = () => {
        if (friends.find((f: any) => f.uid == localStateOnAir.uid)) {
            return "Friend"
        } else if (friendRequests.find((f: any) => f.uid == localStateOnAir.uid)){
            return "Pending"
        } else {
            return "Add Friend"
        }
    }

    return (
        <div className="current-dancer-info">
            <div className="current-dancer-info-inner">
                <div className="current-dancer-info-content">
                    {
                        localStateOnAir ?
                            (<Avatar uid={localStateOnAir.uid} className="current-dancer-info-avatar" />)
                            : null
                    }
                    <IonText className="ion-text-start">
                        <h1 className="ion-no-margin">{localStateOnAir ? localStateOnAir.displayName : ''}</h1>
                    </IonText>
                </div>

                <div className="current-dancer-info-button">
                    {
                        localStateOnAir && localStateOnAir.uid !== user.uid ?
                            (<div onClick={() => handleCreateFriendRequest()}>
                                {/* <Lottie
                                    options={lottieOptions}
                                    height={65}
                                    width={65}
                                    isClickToPauseDisabled={true}
                                    isStopped={heartAnimationIsStopped}
                                    style={{ margin: 0, padding: 0 }}
                                /> */}
                                <IonButton disabled={friendButtonText === "Add Friend" ? false : true} className={`friend-button`}>{friendButtonText}</IonButton>
                            </div>)
                            : null
                    }
                </div>
            </div>
        </div>
    )
}

export default CurrentDancerInfo;