import React, { useState, useEffect } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';


import WebrtcClient from './WebrtcClient'

export const WebrtcContext = React.createContext(undefined);

const WebrtcClientProvider = (props) => {

	const webrtcClient = useStoreState(x => x.webrtcClient.webrtcClient);
	const storeActions = useStoreActions((x) => x.webrtcClient);
	const isJoined = useStoreState(x => x.room.isJoined);


	useEffect(() => {
		if (!props.user) {
			return;
		}
		if (!props.roomId) {
			return;
		}
		if (!isJoined) {
			return;
		}

		if (webrtcClient) {
			if ((webrtcClient._peerId == props.user.uid) && (webrtcClient._roomId == props.roomId)) {
				return;
			}
		}

		const initWebrtcClient = (roomId, user) => {

		    const peerId = user.uid;
		    const useSimulcast = true;
		    const useSharingSimulcast = false;
		    const forceTcp = false;
		    const produce = true;
		    const consume = true;
		    const forceH264 = false;
		    const forceVP9 = false;
		    const svc = false;
		    const datachannel = false;
		    const externalVideo = false;
		    const handlerName = '';

		    const isMicProducerEnabled = false;

			return new WebrtcClient(
		      {
		      	storeActions,
		        roomId,
		        peerId,
		        handlerName,
		        useSimulcast,
		        useSharingSimulcast,
		        forceTcp,
		        produce,
		        consume,
		        forceH264,
		        forceVP9,
		        svc,
		        datachannel,
		        externalVideo,
		        isMicProducerEnabled
		      });
		};

		storeActions.setWebrtcClient(initWebrtcClient(props.roomId, props.user));
	}, [props.roomId, storeActions, props.user, isJoined]);

    return (
      <WebrtcContext.Provider value={webrtcClient}>
        {props.children}
      </WebrtcContext.Provider>
    )

};

export default WebrtcClientProvider
