import React, { useState } from "react";
//import { Link } from "@reach/router";

import { IonButton, IonCheckbox, IonContent, IonHeader, IonItem, IonPage, IonText, IonTitle, IonToolbar } from '@ionic/react';
import Header from '../../components/Header';

import { signInWithFacebook, signInWithGoogle } from "../../firebase";
import './LoginPage.scss';


const LoginPage: React.FC = () => {

  const [error, setError] = useState(null);
  const [isPolicyChecked, setIsPolicyChecked] = useState(false);

  const loginWithFacebookHandler = (event: any) => {
    event.preventDefault();
    try {
      signInWithFacebook();
    } catch (error) {
      setError(error.message);
    }
  };

  const loginWithGoogleHandler = (event: any) => {
    event.preventDefault();
    try {
      signInWithGoogle();
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <IonPage className="login-page">

      <IonContent fullscreen>
        <div>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <div className="ion-text-center">

            <h1>Hi, and welcome to the BlingB beta test!</h1>
            <h3>Sign in to join the party.</h3>

            <br />
            <br />
            <br />
            <br />
            {error}
            <IonButton disabled={!isPolicyChecked} onClick={event => loginWithFacebookHandler(event)}>
              Sign in with Facebook
            </IonButton>

            <br />

            {
              window.location.hostname === "localhost" &&
              <IonButton disabled={!isPolicyChecked} onClick={event => loginWithGoogleHandler(event)}>
                Sign in with Google
              </IonButton>
            }

            <div className="login-policy-section">
              <IonItem>
                <IonCheckbox slot="start" color="primary" checked={isPolicyChecked} onIonChange={e => setIsPolicyChecked(e.detail.checked)} />
                <span>I agree to the BlingB's <a className="text-decoration-none" href="#">Terms of Use</a> and <a className="text-decoration-none" href="#">Privacy & Cookie Policy.</a></span>
              </IonItem>
              {
                !isPolicyChecked && <IonText color="medium"><p>Please accept our terms.</p></IonText>
              }
            </div>
          </div>
        </div>

      </IonContent>
    </IonPage>
  );
};

export default LoginPage;