import { action, thunk, computed } from 'easy-peasy';

import consumersModel from './consumersModel';
import producersModel from './producersModel';
import meModel from './meModel';

const webrtcClientModel = {

  peers: {},

  consumers: consumersModel,
  producers: producersModel,
  me: meModel,
  webrtcClient: null,
  connectionState: 'disconnected',
  isConnected: computed(state => state.connectionState === 'connected'),


  setWebrtcClient: action((state, webrtcClient) => {
    state.webrtcClient = webrtcClient;
  }),

  consume: action((state, uid) => {
    state.webrtcClient.consume(uid);
  }),

  setRoomActiveSpeaker: action((state, payload) => {
    const peerId = payload;
    state.activeSpeakerId = peerId;
  }),

  setConnectionState: action((state, connectionState) => {
    state.connectionState = connectionState;
  }),

  addPeer: action((state, payload) => {
  	const peer = payload;
  	state.peers[peer.id] = peer;
  }),

  addPeerConsumer: action((state, payload) => {

    const peer = state.peers[payload.uid];
    const consumers = peer.consumers;

    state.peers[peer.id] = { ...peer, consumers: [...consumers, payload.consumer.id] }
  }),

  setRoomState: thunk(async (actions, profile, { getStoreActions }) => {
      //getStoreActions().room.setState(profile);
  }),

  notify: action((state, payload) => {
  	console.log(JSON.stringify(payload));
  })

};

export default webrtcClientModel;