import React, { useState } from 'react';
import EditorView from './EditorView';
import DisplayView from './DisplayView';
import './YouTubeVideo.css';
import { useParams } from 'react-router';
import { IonButton } from '@ionic/react';


const isBuilder = true;
const isAdmin = true;
const isEditing = true;

interface YouTubeVideoProps { }

const YouTubeVideo: React.FC<YouTubeVideoProps> = () => {
    const [isEditorOpen, setIsEditorOpen] = useState(true);

    return (
        <div id="youtubevideo" className="lbb-container">
            <DisplayView />
            { isBuilder && isAdmin && isEditorOpen &&
                <EditorView setIsEditorOpen={setIsEditorOpen} />
            }
            <IonButton className="btn-open-editor" onClick={() => { setIsEditorOpen(true) }}>
                Drag {!isEditorOpen && ' / Open Editor'}
            </IonButton>

        </div>
    );
};

export default YouTubeVideo;
