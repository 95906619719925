import React from 'react';
import { IonContent, IonPage } from '@ionic/react';

import GridContainer from '../components/GridContainer';
import Header from '../components/Header';
import TopRightFab from '../components/TopRightFab';
import './RocketPage.css';

const RocketPage: React.FC = () => {
  return (
    <IonPage>
      <Header />
      <IonContent fullscreen>
        <TopRightFab />
        <GridContainer />
      </IonContent>
    </IonPage>
  );
};

export default RocketPage;