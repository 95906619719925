import React, { useContext } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as appPropTypes from './appPropTypes';

import { WebrtcContext } from './WebrtcClientProvider';
import PeerView from './PeerView';


const Peer = (props) =>
{

	const webrtcClient = useContext(WebrtcContext);

	const {
		peer,
		audioConsumer,
		videoConsumer,
		audioMuted,
		faceDetection,
		onSetStatsPeerId
	} = props;

	if (!peer) {
		return null;	
	}

	const showIndicators = false;

	const audioEnabled = (
		Boolean(audioConsumer) &&
		!audioConsumer.locallyPaused &&
		!audioConsumer.remotelyPaused
	);

	const videoVisible = (
		Boolean(videoConsumer) &&
		!videoConsumer.locallyPaused &&
		!videoConsumer.remotelyPaused
	);

	return (
		<div data-component='Peer'>
			<PeerView
				peer={peer}
				audioConsumerId={audioConsumer ? audioConsumer.id : null}
				videoConsumerId={videoConsumer ? videoConsumer.id : null}
				audioRtpParameters={audioConsumer ? audioConsumer.rtpParameters : null}
				videoRtpParameters={videoConsumer ? videoConsumer.rtpParameters : null}
				consumerSpatialLayers={videoConsumer ? videoConsumer.spatialLayers : null}
				consumerTemporalLayers={videoConsumer ? videoConsumer.temporalLayers : null}
				consumerCurrentSpatialLayer={
					videoConsumer ? videoConsumer.currentSpatialLayer : null
				}
				consumerCurrentTemporalLayer={
					videoConsumer ? videoConsumer.currentTemporalLayer : null
				}
				consumerPreferredSpatialLayer={
					videoConsumer ? videoConsumer.preferredSpatialLayer : null
				}
				consumerPreferredTemporalLayer={
					videoConsumer ? videoConsumer.preferredTemporalLayer : null
				}
				consumerPriority={videoConsumer ? videoConsumer.priority : null}
				audioTrack={audioConsumer ? audioConsumer.track : null}
				videoTrack={videoConsumer ? videoConsumer.track : null}
				audioMuted={audioMuted}
				videoVisible={videoVisible}
				videoMultiLayer={videoConsumer && videoConsumer.type !== 'simple'}
				audioCodec={audioConsumer ? audioConsumer.codec : null}
				videoCodec={videoConsumer ? videoConsumer.codec : null}
				audioScore={audioConsumer ? audioConsumer.score : null}
				videoScore={videoConsumer ? videoConsumer.score : null}
				faceDetection={faceDetection}
				onChangeVideoPreferredLayers={(spatialLayer, temporalLayer) =>
				{
					webrtcClient.setConsumerPreferredLayers(
						videoConsumer.id, spatialLayer, temporalLayer);
				}}
				onChangeVideoPriority={(priority) =>
				{
					webrtcClient.setConsumerPriority(videoConsumer.id, priority);
				}}
				onRequestKeyFrame={() =>
				{
					webrtcClient.requestConsumerKeyFrame(videoConsumer.id);
				}}
				onStatsClick={onSetStatsPeerId}
			/>
		</div>
	);
};

Peer.propTypes =
{
	peer             : PropTypes.any, //appPropTypes.Peer.isRequired,
	audioConsumer    : appPropTypes.Consumer,
	videoConsumer    : appPropTypes.Consumer,
	audioMuted       : PropTypes.bool,
	faceDetection    : PropTypes.bool.isRequired,
	//onSetStatsPeerId : PropTypes.func.isRequired
};

const mapStateToProps = (state, { id }) =>
{
	const me = state.webrtcClient.me;
	const peer = state.webrtcClient.peers[id];
	
	const consumersArray = peer ? 
		peer.consumers.map((consumerId) => state.webrtcClient.consumers.consumers[consumerId])
		: [];
	
	const audioConsumer =
		consumersArray.find((consumer) => consumer && consumer.track.kind === 'audio');
	const videoConsumer =
		consumersArray.find((consumer) => consumer && consumer.track.kind === 'video');

	return {
		peer,
		audioConsumer,
		videoConsumer,
		audioMuted    : me.audioMuted,
		faceDetection : false // TODO state.webrtcClient.room.faceDetection
	};
};

const mapDispatchToProps = (dispatch) =>
{
	return {
		onSetStatsPeerId: () => { } // TODO
		//onSetStatsPeerId : (peerId) => dispatch(stateActions.setRoomStatsPeerId(peerId))
	};
};

const PeerContainer = connect(
	mapStateToProps,
	mapDispatchToProps
)(Peer);

export default PeerContainer;
