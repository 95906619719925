import React, { useState, useRef, SetStateAction } from 'react';
import validUrl from 'valid-url';
import { IonButton, IonCol, IonGrid, IonInput, IonItem, IonLabel, IonList, IonRow, IonText } from '@ionic/react';
import './YouTubeVideo.css';
import './EditorView.css';

interface EditorViewProps {
  setIsEditorOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const EditorView: React.FC<EditorViewProps> = (props) => {

  const [url, setUrl] = useState('');
  const [title, setTitle] = useState('');
  const [error, setError] = useState('');


  const titleRef = useRef<HTMLIonInputElement>(null)
  const urlRef = useRef<HTMLIonInputElement>(null)


  const checkYouTubeLink = (link: string): any => {
    fetch('https://www.youtube.com/oembed?format=json&url=' + link.toString(), {
      method: 'GET',
      // mode: 'cors',
    }).then(function (response) {
      console.log("response: ", response)
      if (response.status === 200) {
        console.log("Great")
        return true
      } else {
        return false
      }
    }).catch(function (err) {
      console.log("error: ", err)
      return false
    })
  }

  const saveHandler = () => {
    const enteredTitle = titleRef.current?.value;
    const enteredUrl = urlRef.current?.value;

    if (
      !enteredUrl ||
      !enteredTitle ||
      enteredUrl.toString().trim().length === 0 ||
      enteredTitle.toString().trim().length === 0
    ) {
      setError("Please enter a YouTube url.")
      return
    }
    setError('');
    console.log(enteredUrl)

    if (!validUrl.isUri(enteredUrl.toString())) {
      setError("Please enter a valid url.")
      return
    }

    setError('');

    const isYouTubeValid = checkYouTubeLink(enteredUrl.toString())

    if (!isYouTubeValid) {
      setError('Please enter a YouTube url.')
    }
    setError('');

    console.log(error);
    console.log(enteredTitle, enteredUrl);
    props.setIsEditorOpen(false)



  }


  return (
    <IonGrid className="editor-view-container" draggable={false}>
      <IonRow>
        <IonCol>
          <strong>YouTube Video Module Editor View</strong>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol>
          <IonList className="ion-padding">
            <IonItem>
              <IonLabel color="secondary" position="floating">Title</IonLabel>
              <IonInput type="url" ref={titleRef} value={title} />
            </IonItem>
            <IonItem>
              <IonLabel color="secondary" position="floating">YouTube link</IonLabel>
              <IonInput type="url" ref={urlRef} value={url} />
            </IonItem>
          </IonList>
        </IonCol>
      </IonRow>
      { error &&
        <IonRow>
          <IonCol>
            <IonText color="danger">
              <p>{error}</p>
            </IonText>
          </IonCol>
        </IonRow>
      }
      <IonRow>
        <IonCol>
          <IonButton expand="block" onClick={() => { props.setIsEditorOpen(false) }}>Cancel</IonButton>
        </IonCol>
        <IonCol>
          <IonButton color="light" expand="block" onClick={saveHandler}>Save</IonButton>
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

export default EditorView;