import React, { useState, useEffect } from 'react'

import './Countdown.css';

interface CountdownProps {
    isVisible?: boolean;
}

const Countdown: React.FC<CountdownProps> = ({isVisible}) => {
    
    const [counter, setCounter] = useState(10)
    const countRef = React.useRef(10)
    let doubleDigits: string[]
    let intervalId: any
    
    function playShortTick() {
        const audioEl: any = document.getElementsByClassName("short-tick-audio")[0]
        audioEl.play();
    }

    function playLongTick() {
        const audioEl: any = document.getElementsByClassName("long-tick-audio")[0]
        audioEl.play();
    }

    useEffect(() => {
        if (isVisible) {
            // intervalId = counter > 0 && setTimeout(() => setCounter(c => c - 1), 1000);
            countRef.current > 0 && setTimeout(() => {
                countRef.current = countRef.current - 1
                if (countRef.current == 3 || countRef.current == 2 || countRef.current == 1) {
                    playShortTick()
                }
                if (countRef.current == 0) {
                    playLongTick()
                }
                setCounter(countRef.current)
            }, 1000);
            
        } else {
            clearTimeout(intervalId)
            countRef.current = 10
            setCounter(countRef.current)
        }
    }, [counter, isVisible])

    doubleDigits = counter.toString().padStart(2, '0').split('');
    let digits = doubleDigits.map((digit, index)=>{
        let flip = (index === 0) ? false : true;
        let hereWeGo = counter === 0 ? true : false;
        
        /*return(
             <Digit key={index} flipGradient={flip} hereWeGo={hereWeGo}><span>{digit}</span></Digit>
        )*/
        return (
            <div key={index} className={hereWeGo?'digit here-we-go':'digit'}><span>{digit}</span></div>
            );
    })
    
    
    return (
        <React.Fragment>
            {isVisible ? <div className="countdown-container">{digits}</div> : null}
            <audio className="short-tick-audio">
              <source src="/audio/short_tick.mp3"></source>
            </audio>
            <audio className="long-tick-audio">
              <source src="/audio/long_tick.mp3"></source>
            </audio>
        </React.Fragment>
    )
}

export default Countdown