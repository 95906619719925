import { action, thunk } from 'easy-peasy';
import axios from 'axios';
import { auth } from '../firebase';
import { getAuthTouchUrl } from '../urlFactory';

const authModel = {

    user: null,
    authenticated: false,
    loading: true,

    setUser: action((state, payload) => {
      const user = payload;
      state.user = user;
      if (user) {
      	state.authenticated = true;
      } else {
      	state.authenticated = false;
      }
      state.loading = false;
    }),

    updateUser: action((state, payload) => {
      state.user = { ...state.user, ...payload };
    }),

    touchServer: thunk(async (actions, payload) => {
      await axios.get(getAuthTouchUrl())
        .then(res => {
          if (res.data?.user?.isAdmin) {
            actions.updateUser({ isAdmin: res.data?.user?.isAdmin });
          }
        })
      	.catch(err => err);
    }),

    setDisplayName: action((state, displayName) => {
      if (state.user) {
        state.user = { ...state.user, displayName};
      }
    }),

    setUserSignedOut: action((state, payload) => {
      state.user = null;
      state.authenticated = false;
      state.loading = false;
    }),

    signOut: thunk(async (actions, payload) => {
      auth.signOut().then(function() {
        actions.setUserSignedOut();
      }, function(error) {
        // An error happened.
      });
    })
};

export default authModel;