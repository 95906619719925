import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';


type Hook = (isHome?: boolean | null, activeTeamColour?: string | null, activeTeamColourAccent?: string | null) => void

export const useGlobalStyles: Hook = (isHome, activeTeamColour = null, activeTeamColourAccent = null) => {
  const history = useHistory();
  const roomUrl = history.location.pathname.split("/").pop();

  useEffect(() => {
    if (roomUrl === "boumboumboum") {
      const background: HTMLElement | null = document.querySelector('.grid-container');
      background?.style.setProperty('background', activeTeamColour);

      document.documentElement.style.setProperty('--lbb-shadow', `0 0 8px ${activeTeamColourAccent}, 0 0 4px ${activeTeamColourAccent}, 0 0 7px ${activeTeamColourAccent}`);
    }

  }, [isHome, activeTeamColour, activeTeamColourAccent])

}