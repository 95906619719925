import React, { useState, useEffect } from 'react';
import { IonAvatar, IonButton, IonIcon, IonItem, IonLabel, IonList, IonSearchbar } from '@ionic/react';
import IsOnlineBadge from '../../IsOnlineBadge';

import './PartyPeople.css';

interface PartyPeopleProps {
  state: any;
}

const PartyPeople: React.FC<PartyPeopleProps> = (props) => {

  const [searchText, setSearchText] = useState<string | "">()

  const [partyPeopleList, setPartyPeopleList] = useState<any>([]);
  const fetchDummyData = () => {
    fetch("https://randomuser.me/api/?results=180")
      .then(response => response.json())
      .then(data => {
        const array = data.results.map((result: any) => ({
          ...result,
          displayName: `${result.name.first} ${result.name.last}`,
          avatar: result.picture.medium,
          location: result.location.city,
        }))
        console.log(array)
        setPartyPeopleList(array)
      })
  }

  React.useEffect(() => {
    fetchDummyData();
  }, [])

  return (
    <div id="party-people" className="party-people lbb-container">
        <div className="party-people-wrapper">
        {/* <IonSearchbar value={searchText} onIonChange={e => setSearchText(e.detail.value!)}></IonSearchbar> */}
        {
          partyPeopleList && partyPeopleList.map((user: any, i: number) => {
            return (
                <IonAvatar key={i} className="party-people-avatar" onClick={() => console.log("klikk")}>
                  <img src={user.avatar} />
                  { user.isOnline && <IsOnlineBadge /> }
                </IonAvatar>
            )
          })
        }
        <div></div>
        </div>
    </div>
  );
};

export default PartyPeople;