import React from 'react';
import { useStoreState } from 'easy-peasy';

import { IonAvatar, IonCol, IonGrid, IonIcon, IonRow, IonText } from '@ionic/react';
import { people } from 'ionicons/icons';
import './EventInfo.css'

const EventInfo: React.FC = () => {

    const viewerCount = useStoreState((s: any) => s.room.state.viewerCount);
    const isAdmin = useStoreState((s: any) => s.auth.user.isAdmin);
  

    return (
        <div className="event-info">
            <IonGrid >
                <IonRow>
                    <IonCol size="10" className="event-info-content">
                        <IonAvatar className="event-info-avatar">
                            <img src="https://gravatar.com/avatar/dba6bae8c566f9d4041fb9cd9ada7741?d=identicon&f=y" />
                        </IonAvatar>     
                        <IonText className="ion-text-start">
                            <h1 className="ion-no-margin">BlingB.live</h1>
                            <p className="ion-no-margin">Christine Belle bday</p>
                        </IonText>    
                    </IonCol>
                    <IonCol size="2">
                        {
                            isAdmin && viewerCount ? (
                                <IonText  className="ion-text-end">
                                    <h3 className="ion-no-margin">{ viewerCount }</h3>
                                    <p className="ion-no-margin viewers-text">viewers</p>
                                    <IonIcon className="lbb-header-icon viewers-icon" icon={people} />
                                </IonText>
                            ) : null
                        }
                    </IonCol>       
                </IonRow>   
            </IonGrid>
        </div>
    )
}

export default EventInfo;