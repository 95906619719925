import React, { useEffect } from 'react';
import { useStoreState } from 'easy-peasy';

import { IonButton, IonIcon } from '@ionic/react';
import { banOutline } from 'ionicons/icons';

import './Kick.css';

interface Props {
    uid: any;
}

const Kick: React.FC<Props> = ({uid} : any) => {
    
    const webrtcClient = useStoreState((s: any) => s.webrtcClient.webrtcClient);

    const handleKick = (uid: any) => {
        if (uid) {
            webrtcClient.kick(uid);
        }
    };

    return (
        <div className="webrtc-queue-kick-container">
            <IonButton size="small" onClick={() => handleKick(uid)}>
              <IonIcon slot="icon-only" icon={banOutline} />
            </IonButton>
        </div>
    );

}

export default Kick;