import { useEffect, useMemo, RefObject } from 'react';


const getRefElement = (element : any) => {
  if (element && 'current' in element) {
    return element.current;
  }
  return element;
};

interface Props {
  target?: RefObject<Element> | Element | Node | null;
  options?: MutationObserverInit;
  callback?: MutationCallback;
}

export const useMutationObserver = ({
  target,
  options = {},
  callback
}: Props): void => {
  const observer = useMemo(
    () =>
      new MutationObserver((mutationRecord, mutationObserver) => {
            callback?.(mutationRecord, mutationObserver);
          }),
    [callback]
  );

  useEffect(() => {
    const element = getRefElement(target);

    if (observer && element) {
      observer.observe(element, options);
      return () => observer.disconnect();
    }
  }, [target, observer, options]);
};