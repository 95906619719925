import firebase from 'firebase';


export const putLayout = (payload: any) => {
  return firebase.firestore().collection('rooms').doc(payload.id).set({
    id: payload.id, layout: payload.layout,
    timestamp: firebase.firestore.FieldValue.serverTimestamp()
  }).catch(function(error) {
    console.error('Error writing new room to database', error);
  });	
};

export const getLayoutById = (id: any) => {
  var result = firebase.firestore()
                  .collection('rooms')
                  .doc(id).get();
  return result;
};