import React from 'react';
import { useStoreState } from 'easy-peasy';
import { IonAvatar, IonButton, IonIcon, IonItem, IonList, IonText, IonThumbnail } from '@ionic/react';
import { chevronBackOutline, chevronForwardOutline } from 'ionicons/icons';
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import Marquee from 'react-text-marquee';

import './Queue.css';
import Canvas from '../../../components/canvasBackground';
import useWindowDimensions from '../../../components/utilities/useWindowDimensions';
import { star } from 'ionicons/icons';
import Avatar from '../../Common/Avatar';

interface QueueProps { }

const Queue: React.FC<QueueProps> = () => {
  const { height, width } = useWindowDimensions();
  const queue = useStoreState((s: any) => s.room.state.queue);

  const user = useStoreState((s: any) => s.auth.user);

  const targetRef = React.useRef<HTMLDivElement>(null);
  const [dimensions, setDimensions] = React.useState({ width: 100, height: 100 });

  const [showCanvas, setShowCanvas] = React.useState<Boolean>(false)

  React.useLayoutEffect(() => {
    if (targetRef.current) {
      setDimensions({
        width: targetRef.current!.offsetWidth,
        height: targetRef.current!.offsetHeight
      });
    }
  }, []);


  return (
    <div id="queue" className="lbb-queue-container" ref={targetRef} >

      <IonButton className="canvas-queue-button" style={{ position: "fixed", width: 30, height: 30, bottom: 0, left: 0, zIndex: 1000, opacity: 0 }} onClick={() => setShowCanvas(!showCanvas)}>
        <IonIcon slot="start" icon={star} />
      </IonButton>
      {
        targetRef.current && showCanvas &&
        <Canvas className="lbb-queue-canvas" width={dimensions.width} height={dimensions.height} />
      }
      <div className="lbb-queue-inner">
        <CarouselProvider
          naturalSlideWidth={50}
          naturalSlideHeight={50}
          totalSlides={queue?.length}
          visibleSlides={5}
          step={3}
        >
          <div className="queue-header" >
            <div className="next-dancer">
              <div className="arrow-container">
                <div className="arrow first"></div>
                <div className="arrow second"></div>
                <div className="arrow third"></div>
              </div>
              <IonText className="queue-title">
                <h2>Next <br />Dancer</h2>
              </IonText>
            </div>
          </div>

          <Slider>

            {queue ?
              queue.map((qe: any, idx: any) => {
                const displayName = (user.uid == qe.uid ? "It's you" : qe.displayName);
                if (idx == 0) {
                  return (
                    <Slide index={idx}>

                      <div className="item">
                        <IonThumbnail>
                          <Avatar uid={qe.uid} />
                          <Marquee text={displayName} />
                        </IonThumbnail>
                      </div>

                    </Slide>
                  );
                }
                return (
                  <Slide index={idx}>
                    <div className="item">
                      <IonThumbnail>
                        <Avatar uid={qe.uid} />
                        <Marquee text={displayName} />
                      </IonThumbnail>
                    </div>
                  </Slide>
                );
              })
              : null}

          </Slider>

          <ButtonBack><IonIcon icon={chevronBackOutline} /></ButtonBack>
          <ButtonNext><IonIcon icon={chevronForwardOutline} /></ButtonNext>

        </CarouselProvider>
      </div>

    </div>
  );
};

export default Queue;
