import React from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import './Rankings.css';
import { IonAvatar, IonChip, IonIcon, IonItem, IonLabel, IonList, IonText } from '@ionic/react';

import Avatar from '../../Common/Avatar';

// const players = [
//   {
//     displayName: "Kleidianne Ramos",
//     score: 10,
//     userId: 3
//   },
//   {
//     displayName: "Nerea Morales",
//     score: 15,
//     userId: 4
//   },
//   {
//     displayName: "Alicia Gonzales",
//     score: 20,
//     userId: 13
//   }
// ]

interface RankingsProps { }

const Rankings: React.FC<RankingsProps> = () => {
  const showUserProfile = useStoreActions((s: any) => s.userModal.showUserProfile);
  const x = useStoreState((s: any) => s.room);
  const state = useStoreState((s: any) => s.room.state);


  const players = state.players ? Object.values(state.players).sort((a:any, b:any) => (+b.score) - (+a.score)) : null

  return (
    <div id="rankings" className="lbb-ranking-container">
      <div className="title-wrapper">
        <IonText className="lbb-title" color="primary" >
          <h3>Ranking</h3>
        </IonText>
      </div>     
      {
        players ? (
          <IonList className="lbb-rankings-list">
            {
              players.map((player: any, idx: number) => {
                if (+player.score === 0) {
                  return 
                }
                return (
                  <IonItem key={idx} className="lbb-list-item">
                    <Avatar uid={player.uid} className="lbb-avatar" />
                    <IonLabel>{player.displayName}</IonLabel>
                    <div>{player.score}</div>
                  </IonItem>);
              })
            }
          </IonList>
        ) : (
            <div className="no-data">No ranking data</div>
          )
      }
    </div>
  );
};

export default Rankings;
