import React, { useState, useEffect, createContext } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { auth } from '../firebase';
import axios from 'axios';

export type ProviderValue = any;
export type DefaultValue = undefined;
export type UserContextValue = DefaultValue | ProviderValue;

export const UserContext = createContext<UserContextValue>({ user: null });

const UserProvider = (props: any) => {

  const setUser = useStoreActions((state: any) => state.auth.setUser);
  const touchServer = useStoreActions((state: any) => state.auth.touchServer);
  const user = useStoreState((state: any) => state.auth.user);

  useEffect(() => {
    auth.onAuthStateChanged(userAuth => {
      if (userAuth) {
	    (auth as any).currentUser.getIdToken().then((token: any) => {
	    	axios.defaults.headers.common = {'Authorization': `Bearer ${token}`};
		    setUser(userAuth);
		    touchServer();
	    });
      } else {
	    axios.defaults.headers.common = {};
        setUser(null);
      }
    });	
  }, []);

  return (
    <UserContext.Provider value={user}>
      {props.children}
    </UserContext.Provider>
  );
};

export default UserProvider;