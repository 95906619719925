import React, {useState, useEffect, useRef} from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import ReactRotatingText from 'react-rotating-text';

//import { withRoomContext } from '../../../roomclient/RoomContext';
//import { useSnackbar } from 'notistack';

import './Rating.css';

const Rating = () => {
    
    const emit = useStoreActions((store: any) => store.room.emit);

    const user = useStoreState((store: any) => store.auth.user);
    const roomId = useStoreState((store: any) => store.room.roomId);
    const localStateOnAir = useStoreState((store: any) => store.room.localState.onAir);

    const [targetId, setTargetId] = useState(null);
    const [rating, setRating] = useState(0);
    const isFirstRun = useRef(true);

    const storedRatingsRef = useRef(new Map());

    useEffect(function () {
        if (isFirstRun.current) {
            isFirstRun.current = false;
            return;
        }
        if (targetId && (targetId != user.uid)) {
            storedRatingsRef.current.set(targetId, rating);
            emit({ event: 'rating', payload: { targetId: targetId, rating, scopeId: roomId } });
        }
    }, [rating]);

    useEffect(function () {
        if (localStateOnAir) {
            setTargetId(localStateOnAir.uid);
        } else {
            setTargetId(null);
        }
    }, [localStateOnAir]);

    useEffect(function () {
        if (storedRatingsRef.current.has(targetId)) {
            setRating(storedRatingsRef.current.get(targetId));
        } else {
            setRating(0);
        }
    }, [targetId]);

    useEffect(() => {
        // Init ratings from server
    }, []);


    
    const ratingIcons = [
        {image: <span><img className="diamond-icon" src="../../../assets/diamond.gif" height="60" width="55" /></span>, name: 'diamond' },
        {image: <span><img className="diamond-icon" src="../../../assets/diamond.gif" height="60" width="55"  /></span>, name: 'diamond' },
        {image: <span><img className="diamond-icon" src="../../../assets/diamond.gif" height="60" width="55"  /></span>, name: 'diamond' },
        {image: <span><img className="diamond-icon" src="../../../assets/diamond.gif" height="60" width="55"  /></span>, name: 'diamond' },
        {image: <span><img className="diamond-icon" src="../../../assets/diamond.gif" height="60" width="55"  /></span>, name: 'diamond' }
    ]
    
    const icons = ratingIcons.map((icon, index) => {
        let ratingValue = index + 1
        return (
            <div key={index}>
            <label style={{width: '155px'}} key={index}>
                <input type="radio" className="rating" name="userRating" value={ratingValue} onClick={() => ratingValue == rating ? setRating(0) : setRating(ratingValue)} />
                <div className={['imageWrapper', ratingValue > rating ? 'grayscale' : ''].join(' ')}>{icon.image}</div>                
            </label>
            </div>
        )
    })
    
    return(
        <div className="rating-container" id="rating-container">
            {
              targetId !== user.uid ?
                icons
                :(<h1><ReactRotatingText items={['ON AIR']}/></h1>)
            }
        </div>
    )
}

export default Rating;
