import React, { useState } from 'react';
import { useStoreState } from 'easy-peasy';

import { IonBadge, IonButton, IonLabel, IonSegment, IonSegmentButton, IonSlide, IonSlides } from '@ionic/react';
import Friends from '../Friends';
import PartyPeople from '../PartyPeople';
import Chat from '../Chat';
import "./RocketTabs.css";
import Header from '../../Header';


const slideOpts = {
    initialSlide: 1,
    speed: 400,
    scrollbar: true,
};

const RocketTabs: React.FC = () => {

    const [selectedSegment, setSelectedSegment] = useState("chat")
    const friendRequestCount = useStoreState((s: any) => s.friends.friendRequestCount);

    const changeSegmentHandler = (e: any) => {
        setSelectedSegment(e.detail.value);
    }


    const TabContent: any = (selectedSegment: string) => {
        if (selectedSegment === "friends") {
            return <Friends header={false} selectedArray="friends" />
        } else if (selectedSegment === "requests") {
            return <Friends header={false} selectedArray="requests" />
        } else if (selectedSegment === "chat") {
            return <Chat />
            // } else if (selectedSegment === "party-people") {
            //     return <PartyPeople state />
        } else {
            return null
        }
    }



    return (
        <div className="rocket-tabs">
            <IonSegment value={selectedSegment} onIonChange={changeSegmentHandler}>
                {/* <IonSegmentButton className="rocket-tabs-button ion-no-border" value="party-people">
                    <IonLabel>Party People</IonLabel>
                </IonSegmentButton> */}
                <IonSegmentButton className="rocket-tabs-button ion-no-border last-tab" value="chat">
                    <IonLabel>Chat</IonLabel>
                    
                </IonSegmentButton>

                <IonSegmentButton className="rocket-tabs-button first-tab" value="friends">
                    {/*<IonLabel>Friends<IonBadge color="danger" style={{ position: "absolute", right: -16, top: -4}}>0</IonBadge></IonLabel>*/}
                    <IonLabel>Friends</IonLabel>
                </IonSegmentButton>
                <IonSegmentButton className="rocket-tabs-button" value="requests">
                    <IonLabel>Friend Requests
                        {
                            friendRequestCount > 0 ?
                                (<IonBadge color="danger" style={{ position: "absolute", right: -16, top: -4}}>{ friendRequestCount }</IonBadge>) 
                                : null                           
                        }
                    </IonLabel>
                </IonSegmentButton>

            </IonSegment>
            <div className="rocket-tabs-content">
                {TabContent(selectedSegment)}
            </div>
        </div>

    )

}

export default RocketTabs