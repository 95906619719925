

export function getProfileApiUrl(urlPart)
{
    return window.location.protocol + '//api.' + window.location.hostname + '/profile' + urlPart;
}

export function getStreamingApiUrl(urlPart)
{
    return window.location.protocol + '//api.' + window.location.hostname + '/streaming' + urlPart;
}

export function getGameStateApiUrl({appId, roomId, peerId})
{
    return 'wss://api.' + window.location.hostname + `/storm?appId=${appId}&roomId=${roomId}&peerId=${peerId}`;
}

export function getFriendsApiUrl({token}) {
    return 'wss://api.' + window.location.hostname + '/friends';
}

export function getJoinTokenApiUrl({appId, roomId, peerId}) {
    return window.location.protocol + '//api.' + window.location.hostname + `/gate/enter?uid=${peerId}&channel=${roomId}`;
}

export function getTouchApiUrl({appId, roomId, peerId}) {
    return window.location.protocol + '//api.' + window.location.hostname + `/gate/touch?uid=${peerId}&channel=${roomId}`;
}

export function getAuthTouchUrl() {
    return window.location.protocol + '//api.' + window.location.hostname + `/auth/touch`;
}

